import AxiosInstance from "./AxiosInstant";

let domainName = "";
// let title = '';

window.addEventListener("message", (event) => {
  const receivedData = event.data;

  console.log(receivedData);
  // Ensure the message is from the plugin
  if (receivedData.action == "couponDomainReq") {
    console.log("hi from plugin");
    console.log("domain " + receivedData.domain);
    domainName = receivedData.domain;
  }
});

export const fetchAndValidateSession = async () => {
  let getUniqueId = false;
  const cookieString = document.cookie;
  const cookiesArray = cookieString.split("; ");

  for (let cookie of cookiesArray) {
    const [cookieName, cookieValue] = cookie.split("=");
    console.log("cookieName ", cookieName);
    console.log("cookieValue ", cookieValue);
    console.log(cookieName == "deals2buy_uid");
    if (cookieName == "deals2buy_uid") {
      getUniqueId = true;
      break;
    }
  }
  console.log("getUniqueId ", getUniqueId);
  if (!getUniqueId) {
    fetch("https://api.deals2buy.in/getUniqueId.php", {
      credentials: "include", // Added line
    }).then((response) => {
      try {
        let obj = response;
        console.log(obj);
      } catch (error) {
        console.log("Error : " + error.message + " in " + response);
      }
    });
  }
};

console.log("AxiosInstance ", AxiosInstance);
// const BASE_URL = 'https://lannister.deals2buy.in/cart/api';

export const getCouponCodes = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`getCoupons.php`, data);
};

// favstore coupons
export const getfavStoreCoupons = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`getCoupons.php`, data);
};

export const getProductList = (store_url) => {
  fetchAndValidateSession();

  const headers = new Headers({
    "Content-Type": "application/json",
  });

  console.log("archana", store_url);
  // return AxiosInstance.post(
  //   `getDeal.php`,
  //   { store_url: domainName },
  //   {
  //     mode: "cors",
  //     headers: headers,
  //   }
  // );
  return AxiosInstance.post(
    `getDeal.php`,
    { store_url: store_url },
    {
      mode: "cors",
      headers: headers,
    }
  );
};

export const getSimilarProduct = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`getDeal.php`, { store_url: domainName });
};

export const getCookie = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`validateSession.php`, data);
};

export const getUniqueId = (data) => {
  // fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`getUniqueId.php`, data);
};

export const saveToWishList = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`wishlist.php`, data);
};
export const fetchWishList = (data) => {
  fetchAndValidateSession();

  console.log("fetchWishListid", data);
  // return AxiosInstance.get(`wishlist.php`, data);
  return AxiosInstance.post(`wishlist.php`, data);
};

// getstore list
export const fetchStoreList = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.get(`getStoreList.php?search=${data}`);
};

// add favstore
export const addingFavStore = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`addFavStore.php`, data);
};

// fetchFavStore
export const fetchFavStore = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.get(`getFavStore.php`, data);
};

// create new list
export const createList = (data) => {
  fetchAndValidateSession();
  let obj = new Object({
    list_name: "mylist",
    list_desc: "abcd",
  });
  console.log(JSON.stringify(obj));
  return AxiosInstance.post(`createList.php`, data);
};

// get list
export const getList = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.get(`getList.php`, data);
};

// delete list
export const deleteList = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`removeList.php`, data);
};

// add and update list
export const addUpdateList = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`addUpdateList.php`, data);
};

// remove fav store
export const deleteFavStore = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`removeFavStore.php`, data);
};

// add remove fav,fulfilled-unnfullfiled,and add list api
export const addToList = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`addToList.php`, data);
};

// muteNotifcation and add update notfication
export const addUpdateNotifcation = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`addUpdateNotification.php`, data);
};

// delete notification
export const deleteNotification = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`deleteNotification.php`, data);
};

// getProductNotifcation
export const getProductReviews = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`getProductReview.php`, data);
};

// deleteProduct
export const deleteProduct = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`deleteProduct.php`, data);
};

// getSimilarItems
export const getSimilarItems = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`getSimilarProduct.php`, data);
};

// markAsReadNotification
export const markAsReadNotification = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`markAsRead.php`, data);
};

// getNotifications
export const getNotificationsData = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`getNotifications.php`, data);
};

// editSettings
export const editSettings = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.post(`getNotificationOptions.php`, data);
};

// price evolution
export const priceEvolution = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.get(`getPriceHistory.php`, data);
};

export const fetchPriceHistoryData = (data) => {
  fetchAndValidateSession();

  console.log(data);
  return AxiosInstance.get(`pricehistory.php`, data);
};
