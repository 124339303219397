import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { extractPrice } from "./BuildUrl";

const SingleProductDisplay = ({ singleProduct }) => {
  const [loadingStates, setLoadingStates] = useState({
    titleLoading: true,
    firstWordLoading: true, // Added for firstWord skeleton loading
    imageLoading: true,
    priceLoading: true,
    originalPriceLoading: true,
  });

  const title = singleProduct?.product_title;
  const firstWord = title?.split(" ")[0] || "";

  useEffect(() => {
    if (!singleProduct) {
      setLoadingStates({
        titleLoading: true,
        firstWordLoading: true,
        imageLoading: true,
        priceLoading: true,
        originalPriceLoading: true,
      });
      return;
    }

    // Set a single timer to disable loading for all fields at once
    const timer = setTimeout(() => {
      setLoadingStates({
        titleLoading: false,
        firstWordLoading: false,
        imageLoading: false,
        priceLoading: false,
        originalPriceLoading: false,
      });
    }, 3000); // Set the same loading time for all fields

    return () => clearTimeout(timer);
  }, [singleProduct]);

  return (
    <ul className="singleProductCard">
      <li style={{ background: "transparent", border: "none", padding: "0px" }}>
        {loadingStates.imageLoading ? (
          <Skeleton height={130} width={115} />
        ) : (
          <img
            src={singleProduct.imgSrc || singleProduct.product_image}
            alt={singleProduct.product_title}
            style={{
              height: "130px",
              borderRadius: "10px",
              minWidth: "115px",
              background: "white",
            }}
          />
        )}
        <div style={{ padding: "0px 10px" }}>
          {loadingStates.firstWordLoading ? (
            <Skeleton height={10} width={50} />
          ) : (
            <p
              className="title"
              style={{ textTransform: "uppercase", fontWeight: "600" }}
            >
              {firstWord}
            </p>
          )}

          {loadingStates.titleLoading ? (
            <Skeleton height={10} width={150} />
          ) : (
            <p className="title">{singleProduct.product_title}</p>
          )}

          <div className="price">
            {loadingStates.priceLoading ||
            loadingStates.originalPriceLoading ? (
              <Skeleton height={20} width={80} />
            ) : (
              <>
                {singleProduct.price || singleProduct.product_price ? (
                  <span>
                    ₹ {singleProduct.price || singleProduct.product_price}
                  </span>
                ) : null}

                {singleProduct.originalPrice ||
                singleProduct.product_org_price ? (
                  <span className="line">
                    ₹{" "}
                    {extractPrice(singleProduct.originalPrice) ||
                      extractPrice(singleProduct.product_org_price)}
                  </span>
                ) : null}
              </>
            )}
          </div>
        </div>
      </li>
    </ul>
  );
};

export default SingleProductDisplay;
