import React, { useEffect, useState } from "react";
import Product from "./Product";
import { useGetNotification } from "./ThreeDotsApiCallsHook";
import { handleRedirect, timeAgo, getStoreName } from "./BuildUrl";
import NoDataFound from "./noDataFound";
import Skeleton from "react-loading-skeleton";
import {
  isToday,
  startOfWeek,
  startOfMonth,
  differenceInCalendarDays,
  parseISO,
} from "date-fns";
import { notificationLoading } from "./loadingCards";

const RecentlyViewedCard = ({
  title,
  recentData,
  saveProduct,
  addRemoveSavedProduct,
  border,
  threeDots,
  threeDotsClicked,
  domain,
}) => {
  const {
    loadingGetNotifcations,
    getNotificationsData,
    NotificationsDataFromDerver,
  } = useGetNotification();
  console.log("getStoreName");
  console.log("getNotificationsData", getNotificationsData);
  console.log("loadingGetNotifcations", loadingGetNotifcations);
  const [data, setRecentData] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");
  const [allNotifications, setAllNotifications] = useState([]);
  const [todayRecords, setTodayRecords] = useState([]);
  const [weekRecords, setWeekRecords] = useState([]);
  const [monthRecords, setMonthRecords] = useState([]);
  console.log("todayRecords", todayRecords);
  console.log("weekRecords", weekRecords);
  console.log("monthRecords", monthRecords);

  const filterToday = (data) => {
    return data.filter((product) => {
      const productDate = parseISO(product.date);
      return isToday(productDate); // Check if the product date is today
    });
  };

  const filterThisWeek = (data) => {
    console.log("data44", data);
    const currentDate = new Date();
    console.log("currentDate66", currentDate);
    const startOfWeekDate = startOfWeek(currentDate, { weekStartsOn: 1 });
    console.log("startOfWeekDate55", startOfWeekDate);
    return data.filter((product) => {
      const productDate = parseISO(product.date);
      console.log("productDate55", productDate);
      return (
        productDate >= startOfWeekDate && productDate <= currentDate // Include products within this week
      );
    });
  };

  const filterThisMonth = (data) => {
    const currentDate = new Date();
    const startOfMonthDate = startOfMonth(currentDate);
    console.log("startOfMonthDate55", startOfMonthDate);
    return data.filter((product) => {
      const productDate = parseISO(product.date);
      console.log("productDate66", productDate);
      return productDate >= startOfMonthDate; // Include products from this month
    });
  };

  useEffect(() => {
    if (
      getNotificationsData &&
      getNotificationsData.notifications &&
      Object.keys(getNotificationsData.notifications).length > 0
    ) {
      const notificationsArray = Object.keys(getNotificationsData.notifications)
        .filter((key) => key !== "image_base_url")
        .map((key) => getNotificationsData.notifications[key]);
      console.log("notificationsArray", notificationsArray);
      // Filter data and set the title based on filtered results
      const filteredToday = filterToday(notificationsArray);
      console.log("filteredToday44", filteredToday);
      const filteredThisWeek = filterThisWeek(notificationsArray);
      console.log("filteredThisWeek44", filteredThisWeek);
      const filteredThisMonth = filterThisMonth(notificationsArray);
      console.log("filteredThisMonth44", filteredThisMonth);
      if (title == "All") {
        setTodayRecords(filteredToday);
        setWeekRecords(filteredThisWeek);
        setMonthRecords(filteredThisMonth);
      } else if (title == "Recent") {
        setAllNotifications(notificationsArray);
      }
      setBaseUrl(getNotificationsData.notifications.image_base_url);
    }
  }, [getNotificationsData]);

  const getProductUrl = (url) => {
    handleRedirect(url, "", true, domain);
  };

  return (
    <>
      {loadingGetNotifcations ? (
        // Show loading skeleton
        <ul
          className="notificationCard"
          style={{
            padding: "0px 14px",
          }}
        >
          {Array.from({ length: 4 }, (_, index) => notificationLoading(index))}
        </ul>
      ) : (
        <>
          {title === "All" &&
            ((todayRecords && todayRecords.length > 0) ||
            (weekRecords && weekRecords.length > 0) ||
            (monthRecords && monthRecords.length > 0) ? (
              <>
                {todayRecords && todayRecords.length > 0 && (
                  <>
                    <h3>Today</h3>

                    <ul
                      className="notificationCard"
                      style={{
                        padding: "0px 14px",
                        paddingBottom: "10px",
                      }}
                    >
                      {todayRecords.map((product, index) => (
                        <li key={product.id}>
                          <img
                            src={baseUrl + product.product_image}
                            onClick={() => getProductUrl(product.product_url)}
                          />
                          <div
                            //className="productDetail"
                            style={{
                              padding: "0px 10px",
                            }}
                            onClick={() => getProductUrl(product.product_url)}
                          >
                            <div>
                              <p
                                className="title"
                                style={{
                                  textTransform: "uppercase",
                                  fontWeight: "600",
                                }}
                              >
                                {product.notification_setting_name}
                              </p>
                            </div>
                            <div className="price">
                              <span> ₹ {product.discount_price}</span>
                              <span className="line">
                                ₹ {product.original_price}{" "}
                              </span>
                            </div>
                            <div>
                              <p
                                className="title"
                                style={{
                                  textTransform: "uppercase",
                                  //fontWeight: "600",
                                }}
                              >
                                {product.product_url &&
                                  getStoreName(product.product_url)}
                              </p>
                            </div>
                            <div>
                              <small>{timeAgo(product.date)}</small>
                            </div>
                          </div>
                          {threeDots ? (
                            <div onClick={() => threeDotsClicked(product)}>
                              <i
                                className="fa fa-ellipsis-v threeDots"
                                aria-hidden="true"
                              ></i>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
                {weekRecords && weekRecords.length > 0 && (
                  <>
                    <h3>This Week</h3>
                    <ul
                      className="notificationCard"
                      style={{
                        padding: "0px 14px",
                        paddingBottom: "10px",
                      }}
                    >
                      {weekRecords.map((product, index) => (
                        <li key={product.id}>
                          <img
                            src={baseUrl + product.product_image}
                            onClick={() => getProductUrl(product.product_url)}
                          />
                          <div
                            //className="productDetail"
                            style={{
                              padding: "0px 10px",
                            }}
                            onClick={() => getProductUrl(product.product_url)}
                          >
                            <div>
                              <p
                                className="title"
                                style={{
                                  textTransform: "uppercase",
                                  fontWeight: "600",
                                }}
                              >
                                {product.notification_setting_name}
                              </p>
                            </div>

                            <div className="price">
                              <span> ₹ {product.discount_price}</span>
                              <span className="line">
                                ₹ {product.original_price}{" "}
                              </span>
                            </div>
                            <div>
                              <p
                                className="title"
                                style={{
                                  textTransform: "uppercase",
                                  //fontWeight: "600",
                                }}
                              >
                                {product.product_url &&
                                  getStoreName(product.product_url)}
                              </p>
                            </div>
                            <div>
                              <small>{timeAgo(product.date)}</small>
                            </div>
                          </div>
                          {threeDots ? (
                            <div onClick={() => threeDotsClicked(product)}>
                              <i
                                className="fa fa-ellipsis-v threeDots"
                                aria-hidden="true"
                              ></i>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
                {monthRecords && monthRecords.length > 0 && (
                  <>
                    <h3>This Month</h3>
                    <ul
                      className="notificationCard"
                      style={{
                        padding: "0px 14px",
                        paddingBottom: "10px",
                      }}
                    >
                      {monthRecords.map((product, index) => (
                        <li key={product.id}>
                          <img
                            src={baseUrl + product.product_image}
                            onClick={() => getProductUrl(product.product_url)}
                          />
                          <div
                            //className="productDetail"
                            style={{
                              padding: "0px 10px",
                            }}
                            onClick={() => getProductUrl(product.product_url)}
                          >
                            <div>
                              <p
                                className="title"
                                style={{
                                  textTransform: "uppercase",
                                  fontWeight: "600",
                                }}
                              >
                                {product.notification_setting_name}
                              </p>
                            </div>

                            <div className="price">
                              <span> ₹ {product.discount_price}</span>
                              <span className="line">
                                ₹ {product.original_price}{" "}
                              </span>
                            </div>
                            <div>
                              <p
                                className="title"
                                style={{
                                  textTransform: "uppercase",
                                  //fontWeight: "600",
                                }}
                              >
                                {product.product_url &&
                                  getStoreName(product.product_url)}
                              </p>
                            </div>
                            <div>
                              <small>{timeAgo(product.date)}</small>
                            </div>
                          </div>
                          {threeDots ? (
                            <div onClick={() => threeDotsClicked(product)}>
                              <i
                                className="fa fa-ellipsis-v threeDots"
                                aria-hidden="true"
                              ></i>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </>
            ) : (
              <div style={{ margin: "15px", width: "auto" }}>
                <NoDataFound title="No data found" />
              </div>
            ))}
          {allNotifications && allNotifications.length > 0 && (
            <>
              <ul className="notificationCard">
                <li key={allNotifications[0].id} style={{ padding: "0px" }}>
                  <img
                    src={baseUrl + allNotifications[0].product_image}
                    onClick={() =>
                      getProductUrl(allNotifications[0].product_url)
                    }
                  />
                  <div
                    //className="productDetail"
                    style={{
                      padding: "0px 10px",
                    }}
                    onClick={() =>
                      getProductUrl(allNotifications[0].product_url)
                    }
                  >
                    <div>
                      <p
                        className="title"
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "600",
                        }}
                      >
                        {(() => {
                          const productName = allNotifications[0]?.product_name;
                          const wordsArray = productName
                            ? productName.split(" ")
                            : [];
                          const firstWord = wordsArray[0] || "";
                          return firstWord;
                        })()}
                      </p>
                    </div>
                    <div>
                      <p
                        className="title"
                        // style={{
                        //   whiteSpace: "normal",
                        //   textTransform: "uppercase",
                        //   fontWeight: "600",
                        //   WebkitLineClamp: "1",
                        // }}
                      >
                        {allNotifications[0].product_name}
                      </p>
                    </div>
                    <div className="price">
                      <span> ₹ {allNotifications[0].discount_price}</span>
                      <span className="line">
                        ₹ {allNotifications[0].original_price}{" "}
                      </span>
                    </div>

                    {/* <div>
                      <small>{timeAgo(allNotifications[0].date)}</small>
                    </div> */}
                  </div>
                  {threeDots ? (
                    <div onClick={() => threeDotsClicked(allNotifications[0])}>
                      <i
                        className="fa fa-ellipsis-v threeDots"
                        aria-hidden="true"
                      ></i>
                    </div>
                  ) : (
                    ""
                  )}
                </li>
              </ul>
            </>
          )}
        </>
      )}
    </>
  );
};

export default RecentlyViewedCard;
