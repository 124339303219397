import { useEffect, useState, useRef } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createList,
  fetchList,
  deleteList,
  addUpdateList,
  resetDeleteList,
} from "./actions";
import "./EditeCss.css";
import CreateNewList from "./createNewList";
import DropDownComponent from "./dropdownComponent";
import RecentlyWivewed from "./recentlyViewed";
import Slider from "./reusableSlider";
import SingleProductDisplay from "./singleProductComponent";
import StarRatings from "react-star-ratings";
import { handleRedirect } from "./BuildUrl";
import DeleteSavedDeals from "./deleteSavedDeals";
import { showToast } from "./Toast";
const EditList = ({
  editListData,
  productsList,
  listData,
  closeEditListBox,
  openEditListBox,
  editPen,
  Headding,
  btnText1,
  btnText2,
  marginBottom,
  addToListFunction,
  threeDotsEvent,
  setSelectList,
  showShareProducts,
  editSetting,
  similarProduct,
  notificationOptions,
  fetchListData,
  addToList,
  priceEvolution,
  priceEvolutionData,
  gap,
  height,
  addToNotifyFunction,
  setEditSetting,
  handleRadioChange,
  lastChangesSaving,
  listHeight,
  selectedListNames,
  checkedNotificationOption,
  onConfirmation,
  isReviews,
  domain,
  confirmedIds,
}) => {
  console.log("listData ", listData);
  const isNotificationData =
    listData &&
    listData[0] &&
    listData[0].hasOwnProperty("notification_master_id");

  console.log("btnText2 ", btnText2);

  const [listName, setListName] = useState("");
  const [listDesc, setListDesc] = useState("");
  const [listId, setListId] = useState("");
  const [closeList, setCloseList] = useState(false);
  const [createList, setCreateList] = useState(false);
  const boxRef = useRef(null);

  const dispatch = useDispatch();

  //  edit list handling
  const [listFilterBox, setListFilterBox] = useState(false);
  const [openFilterBox, setOpenFilterBox] = useState(false);

  const handleNotificationSelect = (productId, selectedOption) => {
    // Implement logic to handle notification option selection for productId
    console.log(
      `Selected notification for product ${productId}:`,
      selectedOption
    );
  };

  const handleListOptionSelect = (productId, selectedOption) => {
    // Implement logic to handle list option selection for productId
    console.log(
      `Selected list option for product ${productId}:`,
      selectedOption
    );
  };

  const getProductUrl = (url) => {
    // const dealUrl = buildUrl(uuid, url, platform);
    console.log("domain ", domain);
    handleRedirect(url, "", true, domain);
  };

  const [savedClicked, setSavedClicked] = useState(false);
  const saveNewList = (e) => {
    e.preventDefault();
    // Logic to save the new list
    console.log("List Name:", listName);
    console.log("List Description:", listDesc);
    // list.push(listName)
    let obj = new Object({
      list_name: listName,
      list_desc: listDesc,
      list_id: listId,
    });
    if (listName != "") {
      if (editListData) editListData(obj);
      dispatch(addUpdateList(obj));
    }
    // Reset the form
    setListName("");
    setListDesc("");
    openEditListBox();
    setCloseList(false);
    setSavedClicked(true);
  };
  const createNewListDes = (e) => {
    setListDesc(e.target.value);
    console.log("createNewListDes ", listDesc);
  };
  const createNewList = (e) => {
    setListName(e.target.value);
    console.log("createNewList ", listName);
  };
  const closeEditList = () => {
    if (closeEditListBox) {
      closeEditListBox();
      // openEditListBox()
      setCloseList(false);
    } else {
      setSelectList(false);
      threeDotsEvent();
    }
    setCloseList(false);
    console.log("closeEditListBox ");
  };

  const EditList = (list) => {
    setListName(list.list_name);
    setListDesc(list.list_desc);
    setListId(list.list_id);
    // setCloseList(true);
  };
  const cancelCreateAndOpenAddList = () => {
    console.log("close create list box");
    setCreateList(false);
    setCloseList(false);
  };

  // const deleteListFunction = (e) => {
  //   console.log(e);
  //   e.preventDefault();
  //   deleteList({ list_id: listId });
  // };
  const [isDeleteList, setIsDeleteList] = useState(false);
  const [deleteAlertBox, setDeleteAlertBox] = useState(false);
  const deleteListFunction = (e) => {
    setDeleteAlertBox(true);
    // e.preventDefault();
  };

  useEffect(() => {
    console.log("deleteAlertBox ", deleteAlertBox);
    if (deleteAlertBox == true) {
      setCloseList(true);
    } else {
      setCloseList(false);
    }
  }, [deleteAlertBox]);

  useEffect(() => {
    console.log("isDeleteList ", isDeleteList);
    if (isDeleteList == true) {
      // Logic to save the new list
      console.log("listId:", listId);
      // list.push(listName)
      let obj = new Object({ list_id: listId });
      if (listId != "") {
        // editListData(obj);
        dispatch(deleteList(JSON.stringify(obj)));
      }
      // Reset the form
      setListName("");
      setListDesc("");
    }
  }, [isDeleteList]);

  const addUpdateData = useSelector((state) => state.addUpdateData);
  useEffect(() => {
    console.log("addUpdateData ", addUpdateData);
    if (
      savedClicked &&
      addUpdateData != undefined &&
      addUpdateData.message != undefined &&
      addUpdateData.message.includes("List updated")
    ) {
      dispatch(fetchList({}));
    }
  }, [addUpdateData, savedClicked]);

  const deleteListData = useSelector((state) => state.deleteListData);
  useEffect(() => {
    console.log("deleteListData", deleteListData);
    if (
      isDeleteList &&
      deleteListData != undefined &&
      deleteListData.message != undefined &&
      deleteListData.message.includes("list deleted")
    ) {
      dispatch(fetchList({}));
      console.log("listData ", listData.length > 1);
      if (listData.length > 1) {
        openEditListBox();
        setCloseList(false);
        setDeleteAlertBox(false);
      } else {
        closeEditListBox();
        setCloseList(false);
      }
      showToast("success", deleteListData.message);
      dispatch(resetDeleteList());
    }
  }, [deleteListData, isDeleteList]);

  const [selectedIds, setSelectedIds] = useState([]);

  // Function to retrieve the selected IDs from localStorage
  // const getSelectedListIds = () => {
  //   const storedListIds = localStorage.getItem("selectedLists");
  //   if (storedListIds) {
  //     try {
  //       return JSON.parse(storedListIds).join(',');
  //     } catch (error) {
  //       console.error("Error parsing stored list IDs:", error);
  //       return [];
  //     }
  //   }
  //   return [];
  // };
  // Initialize selectedIds from localStorage on component mount
  // useEffect(() => {
  //   const storedListIds = localStorage.getItem("selectedLists")
  //     ? JSON.parse(localStorage.getItem("selectedLists")).join(",")
  //     : "Please choose";
  //   if (storedListIds.length > 0) {
  //     setSelectedIds(storedListIds.split(",").map((id) => id.trim()));
  //   }

  //   const notificationId = localStorage.getItem("notificationId")
  //     ? JSON.parse(localStorage.getItem("notificationId"))
  //     : "Please choose";
  //   console.log("notificationId ", notificationId);
  //   setnotificationId(notificationId);
  // }, []);

  useEffect(() => {
    // const storedListIds = localStorage.getItem("selectedLists")
    //   ? JSON.parse(localStorage.getItem("selectedLists")).join(",")
    //   : "Please choose";
    // if (storedListIds.length > 0) {
    //   setSelectedIds(storedListIds.split(",").map((id) => id.trim()));
    // }
    let productListIds = [];
    if (
      !showShareProducts &&
      !editSetting &&
      !similarProduct &&
      !priceEvolution &&
      !isReviews &&
      !deleteAlertBox
    ) {
      if (productsList && productsList.list_id) {
        // Check if list_id is not empty and split it by commas to create an array
        productListIds =
          productsList.list_id !== "" ? productsList.list_id.split(",") : [];

        setSelectedIds(productListIds);
      }
    }
    const notificationId = localStorage.getItem("notificationId")
      ? JSON.parse(localStorage.getItem("notificationId"))
      : "Please choose";
    console.log("notificationId ", notificationId);
    setnotificationId(notificationId);
  }, []);

  // const handleCheckboxChange = (event, listId) => {
  //   let updatedSelectedIds;
  //   if (event.target.checked) {
  //     updatedSelectedIds = [...selectedIds, listId.toString()];
  //   } else {
  //     updatedSelectedIds = selectedIds.filter((id) => id !== listId.toString());
  //   }
  //   setSelectedIds(updatedSelectedIds);

  //   // Join the array into a string of IDs separated by commas
  //   const selectedListArr = updatedSelectedIds.map(Number);;

  //   console.log('updatedSelectedIds ',selectedListArr)
  //   // Store the string in localStorage
  //   localStorage.setItem('selectedLists', JSON.stringify(selectedListArr));
  // };

  const handleCheckboxChange = (event, listId) => {
    let updatedSelectedIds;
    if (event.target.checked) {
      updatedSelectedIds = [...selectedIds, listId.toString()];
    } else {
      updatedSelectedIds = selectedIds.filter((id) => id !== listId.toString());
    }

    setSelectedIds(updatedSelectedIds);

    // Join the array into a string of IDs separated by commas
    // const selectedListArr = updatedSelectedIds.map(Number);

    // console.log("updatedSelectedIds ", selectedListArr);
    // Store the string in localStorage
    // localStorage.setItem("selectedLists", JSON.stringify(selectedListArr));
  };

  const [notificationId, setnotificationId] = useState(
    localStorage.getItem("notificationId")
      ? JSON.parse(localStorage.getItem("notificationId"))
      : ""
  );
  useEffect(() => {
    console.log("notificationId ", notificationId);
    if (notificationId != "")
      localStorage.setItem("notificationId", JSON.stringify(notificationId));
    setnotificationId(notificationId);
  }, [notificationId]);

  const confirmChanges = (selectedIds) => {
    console.log("confirmed");

    onConfirmation(selectedIds);
    setEditSetting(true);
  };

  const createListFunction = () => {
    console.log("createListFunction");
    setCreateList(true);
    setCloseList(true);
    //  setSelectList(false);
    //  threeDotsEvent()
  };

  const saveNotificationChanges = () => {
    const formattedIds = selectedIds;
    console.log("selectedIds ", selectedIds);
    addToListFunction(formattedIds);
    setCloseList(true);
  };

  console.log("priceEvolution ", listData);
  return (
    <>
      {deleteAlertBox == true ? (
        <DeleteSavedDeals
          setIsDelete={setIsDeleteList}
          setIsDeletePopup={setDeleteAlertBox}
          message={"list"}
        />
      ) : (
        <>
          {!showShareProducts &&
          !editSetting &&
          !similarProduct &&
          !priceEvolution &&
          !isReviews &&
          !deleteAlertBox ? (
            <>
              <div
                className="filterOverlay"
                style={{ display: "flex", height: "100vh" }}
              >
                <div
                  className="addListForm"
                  style={{
                    height: height ? height : "initial",
                    overflow: "hidden",
                    gap: "18px",
                  }}
                >
                  <div className="model-header">
                    {Headding ? (
                      <h2>{Headding}</h2>
                    ) : (
                      <>
                        {closeList ? (
                          <h2 style={{ position: "relative", padding: "0px" }}>
                            Edit List
                          </h2>
                        ) : (
                          <h2 style={{ padding: "0px" }}> List</h2>
                        )}
                      </>
                    )}

                    <div className="model-header-icon" onClick={closeEditList}>
                      <i
                        className="fa fa-close"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                  <div>
                    {!closeList ? (
                      <ul
                        className="notificationList checkAddToList"
                        style={{
                          maxHeight: listHeight ? listHeight : "400px",
                          overflowY: "scroll",
                          marginBottom: marginBottom ? marginBottom : "",
                        }}
                      >
                        {listData !== undefined &&
                          listData.map((list, index) => (
                            <li
                              className={
                                Headding !== undefined ? "" : "editingList"
                              }
                              key={index}
                              // style={{ height: "42px", padding: "24px 0px" }}
                            >
                              {Headding !== undefined ? (
                                <>
                                  <input
                                    type={
                                      isNotificationData ? "radio" : "checkbox"
                                    }
                                    name={
                                      isNotificationData
                                        ? "notification"
                                        : "currentWebsite"
                                    }
                                    value={
                                      isNotificationData
                                        ? list.notification_master_id
                                        : list.list_id
                                    }
                                    onChange={(e) =>
                                      isNotificationData
                                        ? setnotificationId(
                                            list.notification_master_id
                                          )
                                        : handleCheckboxChange(e, list.list_id)
                                    }
                                    checked={
                                      isNotificationData
                                        ? list.notification_master_id ==
                                            productsList.notification_id ||
                                          notificationId ==
                                            list.notification_master_id
                                        : selectedIds.includes(
                                            list.list_id.toString()
                                          ) // Check if selectedIds includes the list_id
                                    }
                                  />
                                  <label>
                                    {" "}
                                    {list.list_name ||
                                      list.notification_title ||
                                      list}
                                  </label>
                                </>
                              ) : (
                                <>
                                  <span>
                                    {list.list_name ||
                                      list.notification_title ||
                                      list}
                                  </span>
                                  <span
                                    onClick={() => {
                                      EditList(list);
                                      setCloseList(true);
                                    }}
                                  >
                                    <i
                                      className="fa-solid fa-pen"
                                      style={{
                                        marginRight: "10px",
                                        marginLeft: "15px",
                                        fontSize: "13px",
                                        display: editPen ? editPen : "block",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </span>
                                </>
                              )}
                            </li>
                          ))}
                      </ul>
                    ) : (
                      <form style={{ gap: "18px", padding: "0" }}>
                        <input
                          type="text"
                          name=""
                          id="listName"
                          placeholder="List Name"
                          value={listName}
                          onChange={createNewList}
                          maxLength={20}
                        />
                        <textarea
                          type="text"
                          name=""
                          id="listDescription"
                          placeholder="List Description (optional)"
                          value={listDesc}
                          onChange={createNewListDes}
                          style={{ height: "100px", resize: "none" }}
                          maxLength={100}
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            // width: "fit-content",
                            gap: "14px",
                          }}
                        >
                          <button
                            type="button"
                            className="createNewList"
                            style={{
                              width: "50%",
                              // padding: "0px 20px",
                              // minWidth: "inherit",
                            }}
                            onClick={deleteListFunction}
                          >
                            Delete
                          </button>
                          <button
                            type="button"
                            className="createNewList"
                            style={{
                              width: "50%",
                              // padding: "0px 20px",
                              // minWidth: "inherit",
                            }}
                            onClick={saveNewList}
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    )}

                    {Headding != undefined ? (
                      <>
                        <button
                          type="button"
                          className="createNewList"
                          // style={{
                          //   padding: "10px",
                          // }}
                          onClick={() => {
                            Headding == "Notify When" || btnText2 == undefined
                              ? confirmChanges(selectedIds)
                              : lastChangesSaving(selectedIds);
                          }}
                        >
                          {btnText1}
                        </button>
                        {btnText2 != undefined ? (
                          <button
                            type="button"
                            className="createNewList"
                            // style={{
                            //   padding: "10px",
                            // }}
                            onClick={createListFunction}
                          >
                            {btnText2}
                          </button>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              {createList ? (
                <CreateNewList
                  updateProductList={EditList}
                  closeCreatList={
                    btnText2 != undefined
                      ? cancelCreateAndOpenAddList
                      : openEditListBox
                  }
                  height={"auto"}
                  top={"162px"}
                />
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              {editSetting ? (
                <>
                  {productsList !== undefined &&
                    productsList.length > 0 &&
                    productsList.map((product) => (
                      <div
                        className="container addListForm"
                        key={product.id}
                        // style={{ width: "100%", gap: "0px" }}
                      >
                        <div className="model-header">
                          <h2>Edit Setting</h2>
                          <div
                            className="model-header-icon"
                            onClick={() => threeDotsEvent("Some Data")}
                            //style={{ top: "1%" }}
                          >
                            <i
                              class="fa fa-close"
                              // style={{ marginTop: "11px", cursor: "pointer" }}
                            ></i>
                          </div>
                        </div>

                        <div>
                          <div className="editSetingsection">
                            <SingleProductDisplay singleProduct={product} />
                            <div className="editSetingsection">
                              {/* <SingleProductDisplay singleProduct={product} /> */}

                              <div
                                className="filterbox"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                  // margin: "20px 0px",
                                }}
                              >
                                <div
                                  className="filterByLastSaved "
                                  style={{ width: "auto" }}
                                >
                                  <span>Notify when:</span>{" "}
                                  <span
                                    className="selectedOptions"
                                    style={{ width: "239px" }}
                                  >
                                    {checkedNotificationOption}
                                  </span>
                                  <div
                                    onClick={() => {
                                      // setOpenFilterBox(!openFilterBox);
                                      addToNotifyFunction();
                                    }}
                                    className={
                                      listFilterBox
                                        ? "expandDownArrow expandUpArrow"
                                        : "expandDownArrow"
                                    }
                                  ></div>
                                </div>

                                <div
                                  className="filterByLastSaved"
                                  style={{ width: "auto" }}
                                >
                                  <span style={{ width: "104px" }}>
                                    {" "}
                                    Add to list:
                                  </span>
                                  <span className="selectedOptions">
                                    {selectedListNames.length > 0
                                      ? selectedListNames.join(", ")
                                      : "Please choose"}
                                  </span>

                                  <div
                                    onClick={() => {
                                      // setOpenFilterBox(!openFilterBox);
                                      addToList();
                                    }}
                                    className={
                                      openFilterBox
                                        ? "expandDownArrow expandUpArrow"
                                        : "expandDownArrow"
                                    }
                                  ></div>
                                </div>
                              </div>

                              <ul
                                className="listFilter"
                                style={{
                                  display:
                                    listFilterBox || openFilterBox
                                      ? "flex"
                                      : "none",
                                  width: "206px",
                                  position: "fixed",
                                  top: "200px",
                                  zIndex: "100",
                                  margin: "0px 10px",
                                }}
                                ref={boxRef}
                              >
                                {listFilterBox &&
                                  notificationOptions.map((item, index) => (
                                    <li
                                      key={index}
                                      // onClick={() => onItemClick(item)}
                                    >
                                      {item.list_name ||
                                        item.notification_title ||
                                        item}{" "}
                                      {/* Assuming item is string or object with appropriate properties */}
                                    </li>
                                  ))}
                                {openFilterBox &&
                                  fetchListData.data.map((item, index) => (
                                    <li
                                      key={index}
                                      // onClick={() => onItemClick(item)}
                                    >
                                      {item.list_name ||
                                        item.notification_title ||
                                        item}{" "}
                                      {/* Assuming item is string or object with appropriate properties */}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                          <button
                            className="buyProduct"
                            onClick={() => {
                              getProductUrl(product.product_url);
                            }}
                          >
                            Buy The Product
                          </button>
                          <button
                            className="buyProduct"
                            onClick={() => {
                              lastChangesSaving(confirmedIds);
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                ""
              )}

              {showShareProducts ? (
                <div className="filterOverlay" style={{ height: "778px" }}>
                  <div
                    className="modal-content addListForm"
                    style={{
                      height: "350px",
                      gap: "32px",
                      paddingBottom: "50px",
                    }}
                  >
                    <div className="model-header">
                      <h2>Share An Item</h2>
                      <div
                        className="model-header-icon"
                        onClick={() => threeDotsEvent("Some Data")}
                      >
                        <i
                          className="fa fa-close"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    </div>
                    <p>Drop some shopping inspo on your fam.</p>
                    {/* <div className="share-buttons">
                      {productsList.map((option, index) => (
                        <button
                          key={index}
                          className="share-button"
                          onClick={() => {
                            handleRedirect(option.url, "", true, domain);
                          }}
                        >
                          <i className={option.icon}></i> {option.name}
                        </button>
                      ))}
                    </div> */}

                    {productsList.map((option, index) => (
                      <button
                        key={index}
                        className="saveFavSelection"
                        onClick={() => {
                          handleRedirect(option.url, "", true, domain);
                        }}
                      >
                        <i
                          className={option.icon}
                          style={{ marginRight: "10px", height: "20px" }}
                        ></i>{" "}
                        {option.name}
                      </button>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}

              {similarProduct ? (
                <>
                  <div className="filterOverlay" style={{ height: "100vh" }}>
                    <div
                      className="modal-content addListForm "
                      style={{
                        height: "336px",
                        gap: "0px",
                        paddingLeft: "4px",
                      }}
                    >
                      <div className="model-header">
                        <h2>similar Items</h2>
                        <div
                          className="model-header-icon"
                          onClick={() => threeDotsEvent("Some Data")}
                        >
                          <i
                            class="fa fa-close"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                      </div>
                      {productsList !== undefined && productsList.length > 0 ? (
                        <section id="shopAt">
                          <Slider
                            scrollPercent={100}
                            display={"flex"}
                            gap={"10px"}
                            // arrowBackground={"#fbfbf8"}
                            arrowBackground={"#fff"}
                            items={productsList}
                            // title="similar Items"
                            renderItem={(shop, index) => (
                              <li
                                key={index}
                                // onClick={() => getDealsUrl(shop.product_url)}
                                className="storeList"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  border: "1px solid #ddd",
                                }}
                              >
                                <img
                                  src={shop.product_image}
                                  alt="Store logo"
                                  className="icon"
                                />
                              </li>
                            )}
                            height="auto"
                          />
                        </section>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {priceEvolution ? (
                <>
                  <div className="filterOverlay">
                    <div
                      className="modal-content priceevolution "
                      // style={{ gap: "0px" }}
                    >
                      <div className="model-header">
                        <h2>Price Evolution</h2>
                        <div
                          className="model-header-icon"
                          onClick={() => threeDotsEvent("Some Data")}
                          //style={{ right: "32px" }}
                        >
                          <i
                            class="fa fa-close"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                      </div>
                      {productsList !== undefined ? (
                        <>
                          <div
                            className="slider-content"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              transform: "translateX(0%)",
                              width: "fit-content",
                              gap: "45px",
                              marginTop: "25px",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              className="slider-item"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                textAlign: "center",
                                alignItems: "center",
                                padding: "10px",
                                background: "white",
                                borderRadius: "10px",
                              }}
                            >
                              <img
                                src={productsList.product_image}
                                alt="Store logo"
                                className="icon"
                                style={{ height: "155px", width: "120px" }}
                              />
                            </div>

                            {priceEvolutionData.length > 0 ? (
                              ""
                            ) : (
                              <div>
                                <h3 style={{ fontSize: "18px" }}>
                                  No Price Evolution Info
                                </h3>
                                <p>
                                  Price hasn't changed yet. You will find here
                                  the details when the price will change.
                                </p>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {isReviews ? (
                <>
                  <div className="filterOverlay" style={{ height: "100vh" }}>
                    <div
                      className="container addListForm"
                      style={{ width: "100%", gap: "18px" }}
                    >
                      <div className="model-header">
                        <div
                          className="model-header-icon"
                          onClick={() => threeDotsEvent("Some Data")}
                        >
                          <i
                            class="fa fa-close"
                            style={{ marginTop: "11px", cursor: "pointer" }}
                          ></i>
                        </div>
                        <h2>Reviews</h2>
                      </div>
                      {productsList.map((review, index) => (
                        <div key={index} className="review">
                          <div>
                            <StarRatings
                              rating={review.rating}
                              starRatedColor="black"
                              numberOfStars={5}
                              starDimension="15px"
                              starSpacing="2px"
                            />
                            <p className="text">{review.text}</p>
                          </div>
                          <span className="ratingText">{review.rating}/5</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default EditList;
