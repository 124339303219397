// useSaveToWishList.js

import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saveWishList,
  resetSavedWishList,
  getStores,
  fetchFavStores,
  addToList,
  fetchList,
  addUpdateNotification,
  deleteProduct,
  fetchWishList,
} from "./actions";

export const useSaveToWishList = (payload) => {
  const dispatch = useDispatch();
  const savedWishListData = useSelector((state) => state.savedWishListData);

  const handleSaveToWishList = useCallback(
    (item) => {
      dispatch(saveWishList(item));
    },
    [dispatch]
  );

  return {
    savedWishListData,
    handleSaveToWishList,
  };
};

export const useStoreList = (payload) => {
  const dispatch = useDispatch();
  const getStoreListData = useSelector((state) => state.getStoreListData);

  useEffect(() => {
    dispatch(getStores(""));
  }, [dispatch]);

  return {
    getStoreListData,
  };
};

export const useFavStoreList = (payload) => {
  const dispatch = useDispatch();
  const fetchFavStoreData = useSelector((state) => state.fetchFavStoreData);

  useEffect(() => {
    dispatch(fetchFavStores(""));
  }, [dispatch]);

  return {
    fetchFavStoreData,
  };
};

export const useAddToList = (payload) => {
  const dispatch = useDispatch();
  const addToListData = useSelector((state) => state.addToListData);

  const addToFavOrFullFilledOrList = useCallback(
    (item) => {
      dispatch(addToList(item));
    },
    [dispatch]
  );

  useEffect(() => {
    const filter = localStorage.getItem("savedSelectedLists")
      ? JSON.parse(localStorage.getItem("savedSelectedLists"))
      : [];
    if (
      addToListData &&
      (addToListData.message.includes("List added") ||
        addToListData.message.includes("List updated"))
    ) {
      dispatch(fetchWishList({ type: "GET" }));
    }
  }, [addToListData]);

  return {
    addToListData,
    addToFavOrFullFilledOrList,
  };
};

export const useFetchList = (payload) => {
  const dispatch = useDispatch();
  const fetchListData = useSelector((state) => state.fetchListData);
  const fetchListError = useSelector((state) => state.fetchListError);
  const loadingfetchList = useSelector((state) => state.loadingfetchList);

  useEffect(() => {
    dispatch(fetchList(""));
  }, [dispatch]);

  return {
    fetchListData,
  };
};

export const useUpdateNotification = (payload) => {
  const dispatch = useDispatch();
  const addUpdateNotificationData = useSelector(
    (state) => state.addUpdateNotificationData
  );

  const updateNotifcation = useCallback(
    (item) => {
      dispatch(addUpdateNotification(item));
    },
    [dispatch]
  );

  useEffect(() => {
    console.log("addUpdateNotificationData ", addUpdateNotificationData);
    const filter = localStorage.getItem("savedSelectedLists")
      ? JSON.parse(localStorage.getItem("savedSelectedLists"))
      : [];
    if (
      addUpdateNotificationData &&
      addUpdateNotificationData.message.includes("Notification setting updated")
    ) {
      dispatch(fetchWishList({ type: "GET" }));
    }
  }, [addUpdateNotificationData]);

  return {
    addUpdateNotificationData,
    updateNotifcation,
  };
};

export const useDeleteProduct = () => {
  const dispatch = useDispatch();
  const deleteProductData = useSelector((state) => state.deleteProductData);

  const deleteProductfromServer = useCallback(
    (item) => {
      dispatch(deleteProduct(item));
    },
    [dispatch]
  );

  useEffect(() => {
    const filter = localStorage.getItem("savedSelectedLists")
      ? JSON.parse(localStorage.getItem("savedSelectedLists"))
      : [];
    if (deleteProductData.message == "Product deleted successfully") {
      dispatch(fetchWishList({ type: "GET" }));
    }
  }, [deleteProductData, dispatch]);

  return {
    deleteProductData,
    deleteProductfromServer,
  };
};
