import React from "react";
import { ReactComponent as NoDataFoundImage } from "../assets/nodatafound.svg";

const NoDataFound = ({ title, storeName }) => {
  console.log("title", title, "storeName", storeName);
  return (
    <>
      <div className="noActiveDeals" style={{ margin: "0px, 10px" }}>
        <NoDataFoundImage
          className="img-fluid mb-3"
          style={{ maxWidth: "400px", height: "260px" }}
        />

        <h3>{title}</h3>
        {/* <span>Check out other stores for more deals</span> */}
      </div>
    </>
  );
};

export default NoDataFound;
