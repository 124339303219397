import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AnotherCard = () => (
  <div className="another_card">
    <Skeleton height={200} width={150} className="images-size" />
    <Skeleton height={20} width={50} />
    <Skeleton height={10} width={150} />
    <Skeleton height={10} width={150} style={{ margin: "0px", padding: "0" }} />
  </div>
);
export const generateIterablePart = (index) => (
  <div className="items_wrapper" key={index}>
    <AnotherCard />
  </div>
);

export const notificationLoading = () => (
  <li className="product-skeleton-card">
    {/* Image loading skeleton */}
    <Skeleton
      variant="rectangular"
      width={100} // Match the product image width
      height={100} // Match the product image height
    />

    {/* Title loading skeleton */}
    <div style={{ background: "white", padding: "0px 10px" }}>
      <Skeleton
        variant="text"
        width={120} // Match the width of the actual title text
        height={13} // Set height to match title size
        className="title-skeleton" // Optional: a custom class for consistency
      />

      {/* Price loading skeleton */}

      <Skeleton
        variant="text"
        width={80} // Mimic discount price width
        height={10} // Match height of price text
        className="discount-price-skeleton" // Custom class for price
      />

      {/* Date loading skeleton */}
      <Skeleton
        variant="text"
        width={100} // Mimic date text width
        height={10} // Match height of the date text
        className="date-skeleton text-muted" // Similar class for date
        style={{ textTransform: "lowercase", marginTop: "5px" }} // Mimic styling
      />
    </div>
  </li>
);
