import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";

import CouponsList from "./CouponsList";
import FavoriteStoresList from "./FavoriteStoresComponent";
import { useDispatch, useSelector } from "react-redux";
import FavoriteStores from "./FavoriteStores";
import CouponsandSavings from "./CouponsandSavings";
import CouponsSlider from "./CouponsSlider";
import {
  fetchData,
  fetchCookie,
  fetchUniqueId,
  fetchFavStores,
  getStores,
  resetAddFavStore,
  resetRemoveFavStore,
} from "./actions";
import { showToast } from "./Toast";
import NoActiveDeals from "./noActiveDeals";
import SeeAllComponent from "./seeAllComponent";

const CouponsComponent = ({
  domain,
  isCheckoutMode,
  couponPayload,
  handleSeeAllClick,
  setZIndex,
}) => {
  const CouponData = useSelector((state) => state.CouponData);
  const [favStore, setFavStore] = useState(false);
  // const [favStoreList,setFavStoreList] = useState(false);
  const [websiteList, setWebsiteList] = useState([]);
  const [favStoreList, setFavStoreList] = useState([]);
  const dispatch = useDispatch();
  const [expandDiv, setExpandDiv] = useState(false);
  const [seeAllData, setSeeAllData] = useState(false);
  const [couponData, setCouponData] = useState("");
  const [storeName, setstoreName] = useState("");
  console.log("storeName ", storeName);

  // getstoreList data
  const getStoreListData = useSelector((state) => state.getStoreListData);
  const loadingGetStoreList = useSelector((state) => state.loadingGetStoreList);
  const getStoreListError = useSelector((state) => state.getStoreListError);

  const fetchFavStoreData = useSelector((state) => state.fetchFavStoreData);
  const loadingfetchFavStore = useSelector(
    (state) => state.loadingfetchFavStore
  );
  const fetchFavStoreError = useSelector((state) => state.fetchFavStoreError);
  const [getFavStores, setGetFavStores] = useState([]);
  useEffect(() => {
    if (fetchFavStoreData != undefined && fetchFavStoreData.data != undefined) {
      setGetFavStores(fetchFavStoreData.data.sort());
    }
  }, [fetchFavStoreData]);

  const fetchFavStoresData = () => {
    console.log("fetchFavStoresData ");
    dispatch(fetchFavStores(""));
  };

  useEffect(() => {
    dispatch(getStores(""));
    fetchFavStoresData();
  }, []);

  useEffect(() => {
    setWebsiteList(getStoreListData.data);
    console.log("websiteList ", websiteList);
    setFavStoreList(fetchFavStoreData.data);
    console.log("favStoreList ", favStoreList);
  }, [getStoreListData, fetchFavStoreData, couponData]);

  useEffect(() => {
    if (websiteList != undefined) {
      // const updatedArray = websiteList.sort((a, b) =>
      //   a.store_name.localeCompare(b.store_name)
      // );
      // console.log("updateArray websiteList ", updatedArray);

      // setWebsiteList(updatedArray.sort());
      setWebsiteList(websiteList);
    }
  }, [websiteList]);
  useEffect(() => {
    if (favStoreList != undefined) {
      // const updatedArray = favStoreList.sort((a, b) =>
      //   a.store_name.localeCompare(b.store_name)
      // );
      // console.log("updateArray favStoreList ", updatedArray);

      // setFavStoreList(updatedArray.sort());
      setFavStoreList(favStoreList);
    }
  }, [favStoreList]);

  // const favoriteStoreList = (list)=>{
  //   setFavStoreList(!favStoreList);
  //   }
  //   useEffect(()=>{
  //     favoriteStoreList()
  //   },[])

  // copy code function
  const [copyText, setCopyText] = useState(
    new Array(CouponData.length).fill(false)
  );

  const copyToClipboard = async (index, coupons) => {
    try {
      console.log(index);
      let couponCode = coupons
        .filter((_, i) => index === i)
        .map((item) => (item ? item.coupon_code : ""));
      await navigator.clipboard.writeText(couponCode);
      let copyStatus = [...copyText];
      // set text to copied
      copyStatus[index] = true;

      setCopyText(copyStatus);

      // reset text to copy after some time
      setTimeout(() => {
        copyStatus[index] = false;
        setCopyText(copyStatus);
      }, 2000);
    } catch (err) {
      console.log("Failed to copy:", err);
    }
  };

  const openStoresSection = () => {
    // setZIndex(0)
    setFavStore(true);
  };

  const addFavStoreData = useSelector((state) => state.addFavStoreData);
  const removeFavStoreData = useSelector((state) => state.removeFavStoreData);
  // useEffect(() => {
  //   console.log("addFavStoreData ", addFavStoreData);

  //   if (addFavStoreData) {
  //     fetchFavStoresData("");
  //   }
  // }, [addFavStoreData]);
  // useEffect(() => {
  //   console.log("RemoveFavStoreData ", RemoveFavStoreData);

  //   if (RemoveFavStoreData) {
  //     fetchFavStoresData("");
  //   }
  // }, [RemoveFavStoreData]);

  useEffect(() => {
    console.log("addFavStoreData", addFavStoreData);
    if (addFavStoreData && addFavStoreData.status) {
      showToast("success", addFavStoreData.message);
      dispatch(resetAddFavStore());
      fetchFavStoresData("");
    }
  }, [addFavStoreData]);

  useEffect(() => {
    console.log("removeFavStoreData", removeFavStoreData);
    if (removeFavStoreData && removeFavStoreData.status) {
      showToast("success", removeFavStoreData.message);
      dispatch(resetRemoveFavStore());
      fetchFavStoresData("");
    }
  }, [removeFavStoreData]);

  return (
    <>
      {/* {seeAllData == false ? ( */}
      <>
        <div
          style={{ display: couponData.length == 0 ? "flex" : "none" }}
          className="couponsSection"
        >
          <div className="storeSection">
            <div className="favstoreContainer">
              <h3>Your favorite stores</h3>
              <span
                onClick={() => {
                  openStoresSection();
                }}
                style={{ cursor: "pointer" }}
              >
                Add
              </span>
            </div>
            <div className="favStores">
              {" "}
              <FavoriteStores
                isCheckoutMode={isCheckoutMode}
                favStoresList={getFavStores}
                domain={domain}
                setCouponData={setCouponData}
                copyToClipboard={copyToClipboard}
                setstoreName={setstoreName}
                handleSeeAllClick={handleSeeAllClick}
              />{" "}
            </div>
          </div>

          {/* <div className="activeCoupons">
            <h3>Active Coupons</h3>
            {isCheckoutMode == "checkout" ? (
              <>
                {
                  CouponData != undefined &&
                    CouponData.related_coupons != undefined &&
                    CouponData.related_coupons.length && (
                      <div className="expandableDiv">
                        <div className="expandCoupons">
                          <div style={{ textAlign: "start" }}>
                            <h3> coupons found</h3>
                            <small>
                              Will be automatically applied at checkout
                            </small>
                          </div>
                          <div
                            onClick={() => {
                              setExpandDiv(!expandDiv);
                            }}
                            className={
                              expandDiv
                                ? "expandDownArrow expandUpArrow"
                                : "expandDownArrow"
                            }
                          ></div>
                        </div>
                        {expandDiv ? (
                          <CouponsList
                            coupons={CouponData.related_coupons}
                            domain={domain}
                            setCouponData={setCouponData}
                            copyToClipboard={copyToClipboard}
                            handleSeeAllClick={handleSeeAllClick}
                            seeAll={true}
                            couponsColor={"#F3F4F6"}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    )
                  // : (
                  //   <NoActiveDeals />
                  // )
                }
              </>
            ) : (
              <CouponsList
                coupons={CouponData.related_coupons}
                domain={domain}
                title="Active Coupons"
                isCheckoutMode={isCheckoutMode}
                setCouponData={setCouponData}
                copyToClipboard={copyToClipboard}
                handleSeeAllClick={handleSeeAllClick}
                seeAll={true}
              />
            )}
          </div> */}

          <div className="activeCoupons">
            {CouponData !== undefined &&
              CouponData.related_coupons !== undefined &&
              CouponData.related_coupons.length > 0 && ( // Check if coupons exist
                <>
                  <h3>Active Coupons</h3>
                  {isCheckoutMode === "checkout" ? (
                    <div className="expandableDiv">
                      <div className="expandCoupons">
                        <div style={{ textAlign: "start" }}>
                          <h3>
                            {CouponData.related_coupons.length} coupons found
                          </h3>
                          <small>
                            Will be automatically applied at checkout
                          </small>
                        </div>
                        <div
                          onClick={() => {
                            setExpandDiv(!expandDiv);
                          }}
                          className={
                            expandDiv
                              ? "expandDownArrow expandUpArrow"
                              : "expandDownArrow"
                          }
                        ></div>
                      </div>

                      {expandDiv && (
                        <CouponsList
                          coupons={CouponData.related_coupons}
                          domain={domain}
                          setCouponData={setCouponData}
                          copyToClipboard={copyToClipboard}
                          handleSeeAllClick={handleSeeAllClick}
                          seeAll={true}
                          couponsColor={"#F3F4F6"}
                        />
                      )}
                    </div>
                  ) : (
                    <CouponsList
                      coupons={CouponData.related_coupons}
                      domain={domain}
                      title="Active Coupons"
                      isCheckoutMode={isCheckoutMode}
                      setCouponData={setCouponData}
                      copyToClipboard={copyToClipboard}
                      handleSeeAllClick={handleSeeAllClick}
                      seeAll={true}
                    />
                  )}
                </>
              )}
          </div>

          <CouponsandSavings
            domain={domain}
            couponPayload={couponPayload}
            isCheckoutMode={isCheckoutMode}
          />

          <div className="popularCoupons">
            <CouponsSlider
              title="Pouplar Coupons"
              domain={domain}
              coupons={CouponData.popular_coupons}
              copyToClipboard={copyToClipboard}
              websitelist={websiteList}
              favStorelist={favStoreList}
              handleSeeAllClick={handleSeeAllClick}
              setCouponData={setCouponData}
            />
          </div>

          <div className="CouponsAndDeals" style={{ top: "-7px" }}>
            <CouponsSlider
              title="Coupons And Deals"
              domain={domain}
              coupons={CouponData.deals_coupons}
              copyToClipboard={copyToClipboard}
              websitelist={websiteList}
              favStorelist={favStoreList}
              handleSeeAllClick={handleSeeAllClick}
              setCouponData={setCouponData}
            />
          </div>
        </div>

        {favStore ? (
          <FavoriteStoresList
            storeList={websiteList}
            favouriteStoreList={favStoreList}
            domain={domain}
            fetchFavStoresData={fetchFavStoresData}
            storeBox={true}
            setFavStore={setFavStore}
            copyToClipboard={copyToClipboard}
          />
        ) : (
          ""
        )}
        {/* <div
            style={{ display: couponData.length > 0 ? "block" : "none" }}
            onClick={() => {
              setCouponData([]);
            }}
          >
            {" "}
            <i
              class="fa fa-arrow-left icon"
              aria-hidden="true"
              style={{
                fontSize: "25px",
                padding: "20px",
                paddingBottom: "0px",
                cursor:'pointer'
              }}
            ></i>
            <SeeAllComponent
              coupons={couponData}
              domain={domain}
              copyToClipboard={copyToClipboard}
              storeName={storeName}     
        websitelist={websiteList}
        favStorelist={favStoreList}
            />
          </div> */}
      </>
      {/* // ) :
      
      // (
      //   <SeeAllComponent storeName={storeName}     
      //   websitelist={websiteList}
      //   favStorelist={favStoreList}
      //   domain={domain}
      //   fetchFavStoresData={fetchFavStoresData} />
      // )} */}

      {/* end api code */}
    </>
  );
};

export default CouponsComponent;
