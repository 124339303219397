import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSimilarProducts,
  getNotifications,
  markAsRead,
  priceEvolution,
  editSettings,
  deleteNotification,
  getProductReviews,
  fetchPriceHistoryRequest,
} from "./actions";

export const useSimilarItems = (payload) => {
  const dispatch = useDispatch();
  const similarItemsData = useSelector((state) => state.similarItemsData);

  const similarItemsDataFromServer = useCallback(
    (productData) => {
      dispatch(getSimilarProducts(productData));
    },
    [dispatch]
  );

  console.log(similarItemsData);
  return {
    similarItemsData,
    similarItemsDataFromServer,
  };
};

// get notification
export const useGetNotification = (payload) => {
  const dispatch = useDispatch();
  const getNotificationsData = useSelector(
    (state) => state.getNotificationsData
  );
  const loadingGetNotifcations = useSelector(
    (state) => state.loadingGetNotifcations
  );

  const NotificationsDataFromDerver = useCallback(
    (item) => {
      dispatch(getNotifications());
    },
    [dispatch]
  );

  return {
    loadingGetNotifcations,
    getNotificationsData,
    NotificationsDataFromDerver,
  };
};

//   mark as read
export const useMarkAsRead = (payload) => {
  const dispatch = useDispatch();
  const markAsReadData = useSelector((state) => state.markAsReadData);

  const markAsReadDataFromServer = useCallback(() => {
    dispatch(markAsRead());
  }, [dispatch]);

  console.log(markAsReadData);
  return {
    markAsReadData,
    markAsReadDataFromServer,
  };
};

//   price evolution
export const usePriceEvolution = (payload) => {
  const dispatch = useDispatch();
  const priceEvolutionData = useSelector((state) => state.priceEvolutionData);

  const priceEvolutionDataFromServer = useCallback(
    (productData) => {
      dispatch(priceEvolution(productData));
    },
    [dispatch]
  );

  console.log(priceEvolutionData);
  return {
    priceEvolutionData,
    priceEvolutionDataFromServer,
  };
};

//   editSettings

export const useEditSettings = (payload) => {
  const dispatch = useDispatch();
  const editSettingsData = useSelector((state) => state.editSettingsData);

  const getNotificationsOptions = (data) => {
    dispatch(editSettings(data));
  };

  console.log(editSettingsData);
  return {
    editSettingsData,
    getNotificationsOptions,
  };
};

export const useDeleteNotification = (payload) => {
  const dispatch = useDispatch();
  const deleteNotificationData = useSelector(
    (state) => state.deleteNotificationData
  );
  console.log("deleteNotificationDatause", deleteNotificationData);
  const deleteSingleProductNotification = (data) => {
    dispatch(deleteNotification(data));
  };

  return {
    deleteNotificationData,
    deleteSingleProductNotification,
  };
};

export const useProductReview = (payload) => {
  const dispatch = useDispatch();
  const productReviewsData = useSelector((state) => state.productReviewsData);

  const productReviewDataFunction = (data) => {
    dispatch(getProductReviews(data));
  };

  console.log(productReviewsData);
  return {
    productReviewsData,
    productReviewDataFunction,
  };
};

export const usePriceHistory = (payload) => {
  const dispatch = useDispatch();
  const fetchPriceHistoryData = useSelector(
    (state) => state.fetchPriceHistoryData
  );

  const fetchPriceHistoryDataFunction = (data) => {
    dispatch(fetchPriceHistoryRequest());
  };

  return {
    fetchPriceHistoryData,
    fetchPriceHistoryDataFunction,
  };
};
