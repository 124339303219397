// useUniqueIdHook.js

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWishList } from "./actions";

const useGetWishList = () => {
  const dispatch = useDispatch();
  const wishListData = useSelector((state) => state.wishListData);
  const loadingWishList = useSelector((state) => state.loadingWishList);
  const wishListError = useSelector((state) => state.wishListError);
  console.log("loadingWishList", loadingWishList);

  const handleGetToWishList = useCallback(() => {
    dispatch(fetchWishList({ type: "GET" }));
  }, [dispatch]);

  console.log("wishListData ", wishListData);
  if (wishListData && wishListData.data) {
    localStorage.setItem("savedProductData", JSON.stringify(wishListData.data));
  }

  return {
    wishListData,
    loadingWishList,
    handleGetToWishList,
    wishListError,
  };
};

export default useGetWishList;
