// actions.js
export const FETCH_DATA = "FETCH_DATA";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const FETCH_PRODUCT_LIST = "FETCH_PRODUCT_LIST";
export const FETCH_PRODUCT_LIST_SUCCESS = "FETCH_PRODUCT_LIST_SUCCESS";
export const FETCH_PRODUCT_LIST_FAILURE = "FETCH_PRODUCT_LIST_FAILURE";
export const FETCH_SIMILAR_PRODUCT = "FETCH_SIMILAR_PRODUCT";
export const FETCH_SIMILAR_PRODUCT_SUCCESS = "FETCH_SIMILAR_PRODUCT_SUCCESS";
export const FETCH_SIMILAR_PRODUCT_FAILURE = "FETCH_SIMILAR_PRODUCT_FAILURE";
export const FETCH_COOKIE = "FETCH_COOKIE";
export const FETCH_COOKIE_SUCCESS = "FETCH_COOKIE_SUCCESS";
export const FETCH_COOKIE_FAILURE = "FETCH_COOKIE_FAILURE";
export const FETCH_UNIQUEID = "FETCH_UNIQUEID";
export const FETCH_UNIQUEID_SUCCESS = "FETCH_UNIQUEID_SUCCESS";
export const FETCH_UNIQUEID_FAILURE = "FETCH_UNIQUEID_FAILURE";
export const FETCH_WISHLIST = "FETCH_WISHLIST";
export const FETCH_WISHLIST_SUCCESS = "FETCH_WISHLIST_SUCCESS";
export const FETCH_WISHLIST_FAILURE = "FETCH_WISHLIST_FAILURE";
export const SAVE_WISHLIST = "SAVE_WISHLIST";
export const SAVE_WISHLIST_SUCCESS = "SAVE_WISHLIST_SUCCESS";
export const SAVE_WISHLIST_FAILURE = "SAVE_WISHLIST_FAILURE";
export const RESET_SAVED_WISHLIST = "RESET_SAVED_WISHLIST";
export const GET_STORELIST = "GET_STORELIST";
export const GET_STORELIST_SUCCESS = "GET_STORELIST_SUCCESS";
export const GET_STORELIST_FAILURE = "GET_STORELIST_FAILURE";
export const ADD_FAVSTORE = "ADD_FAVSTORE";
export const ADD_FAVSTORE_SUCCESS = "ADD_FAVSTORE_SUCCESS";
export const ADD_FAVSTORE_FAILURE = "ADD_FAVSTORE_FAILURE";
export const RESET_ADD_FAVSTORE = "RESET_ADD_FAVSTORE";
export const FETCH_FAVSTORE = "FETCH_FAVSTORE";
export const FETCH_FAVSTORE_SUCCESS = "FETCH_FAVSTORE_SUCCESS";
export const FETCH_FAVSTORE_FAILURE = "FETCH_FAVSTORE_FAILURE";
export const CREATE_LIST = "CREATE_LIST";
export const CREATE_LIST_SUCCESS = "CREATE_LIST_SUCCESS";
export const CREATE_LIST_FAILURE = "CREATE_LIST_FAILURE";
export const FETCH_LIST = "FETCH_LIST";
export const FETCH_LIST_SUCCESS = "FETCH_LIST_SUCCESS";
export const FETCH_LIST_FAILURE = "FETCH_LIST_FAILURE";
export const FETCH_FAVSTORE_COUPONS = "FETCH_FAVSTORE_COUPONS";
export const FETCH_FAVSTORE_COUPONS_SUCCESS = "FETCH_FAVSTORE_COUPONS_SUCCESS";
export const FETCH_FAVSTORE_COUPONS_FAILURE = "FETCH_FAVSTORE_COUPONS_FAILURE";
export const REMOVE_FAV_STORE = "REMOVE_FAV_STORE";
export const REMOVE_FAV_STORE_SUCCESS = "REMOVE_FAV_STORE_SUCCESS";
export const REMOVE_FAV_STORE_FAILURE = "REMOVE_FAV_STORE_FAILURE";
export const RESET_REMOVE_FAV_STORE = "RESET_REMOVE_FAV_STORE";
export const DELETE_LIST = "DELETE_LIST";
export const DELETE_LIST_SUCCESS = "DELETE_LIST_SUCCESS";
export const DELETE_LIST_FAILURE = "DELETE_LIST_FAILURE";
export const RESET_DELETE_LIST = "RESET_DELETE_LIST";

export const ADDUPDATE_LIST = "ADDUPDATE_LIST";
export const ADDUPDATE_LIST_SUCCESS = "ADDUPDATE_LIST_SUCCESS";
export const ADDUPDATE_LIST_FAILURE = "ADDUPDATE_LIST_FAILURE";
export const RESET_ADDUPDATE_LIST = "RESET_ADDUPDATE_LIST";

export const ADD_TO_LIST = "ADD_TO_LIST";
export const ADD_TO_LIST_SUCCESS = "ADD_TO_LIST_SUCCESS";
export const ADD_TO_LIST_FAILURE = "ADD_TO_LIST__FAILURE";
export const RESET_ADD_TO_LIST = "RESET_ADD_TO_LIST";

export const ADD_UPDATE_NOTIFICATION = "ADD_UPDATE_NOTIFICATION";
export const ADD_UPDATE_NOTIFICATION_SUCCESS =
  "ADD_UPDATE_NOTIFICATION_SUCCESS";
export const ADD_UPDATE_NOTIFICATION_FAILURE =
  "ADD_UPDATE_NOTIFICATION_FAILURE";
export const RESET_ADD_UPDATE_NOTIFICATION = "RESET_ADD_UPDATE_NOTIFICATION";

export const GET_SIMILAR_PRODUCTS = "GET_SIMILAR_PRODUCTS";
export const GET_SIMILAR_PRODUCTS_SUCCESS = "GET_SIMILAR_PRODUCTS_SUCCESS";
export const GET_SIMILAR_PRODUCTS_FAILURE = "GET_SIMILAR_PRODUCTS_FAILURE";

export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const DELETE_PRODUCTS_SUCCESS = "DELETE_PRODUCTS_SUCCESS";
export const DELETE_PRODUCTS_FAILURE = "DELETE_PRODUCTS_FAILURE";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";

export const MARK_AS_READ = "MARK_AS_READ";
export const MARK_AS_READ_SUCCESS = "MARK_AS_READ_SUCCESS";
export const MARK_AS_READ_FAILURE = "MARK_AS_READ_FAILURE";

export const EDIT_SETTINGS = "EDIT_SETTINGS";
export const EDIT_SETTINGS_SUCCESS = "EDIT_SETTINGS_SUCCESS";
export const EDIT_SETTINGS_FAILURE = "EDIT_SETTINGS_FAILURE";

export const PRICE_EVOLUTION = "PRICE_EVOLUTION";
export const PRICE_EVOLUTION_SUCCESS = "PRICE_EVOLUTION_SUCCESS";
export const PRICE_EVOLUTION_FAILURE = "PRICE_EVOLUTION_FAILURE";

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAILURE = "DELETE_NOTIFICATION_FAILURE";
export const RESET_DELETE_NOTIFICATION = "RESET_DELETE_NOTIFICATION";

export const PRODUCT_REVIEWS = "PRODUCT_REVIEWS";
export const PRODUCT_REVIEWS_SUCCESS = "PRODUCT_REVIEWS_SUCCESS";
export const PRODUCT_REVIEWS_FAILURE = "PRODUCT_REVIEWS_FAILURE";

// src/store/priceHistory/priceHistoryTypes.js
export const FETCH_PRICE_HISTORY_REQUEST = "FETCH_PRICE_HISTORY_REQUEST";
export const FETCH_PRICE_HISTORY_SUCCESS = "FETCH_PRICE_HISTORY_SUCCESS";
export const FETCH_PRICE_HISTORY_FAILURE = "FETCH_PRICE_HISTORY_FAILURE";

// couponscode
export const fetchData = (data) => ({
  type: FETCH_DATA,
  payload: data,
});

export const fetchDataSuccess = (data) => ({
  type: FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchDataFailure = (error) => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

// favStore Coupons
export const fetchFavStoreCoupons = (data) => ({
  type: FETCH_FAVSTORE_COUPONS,
  payload: data,
});

export const fetchFavStoreCouponsSuccess = (data) => ({
  type: FETCH_FAVSTORE_COUPONS_SUCCESS,
  payload: data,
});

export const fetchFavStoreCouponsFailure = (error) => ({
  type: FETCH_FAVSTORE_COUPONS_FAILURE,
  payload: error,
});
// productlist
export const fetchProductList = (data) => (
  console.log("fetchdata", data),
  {
    type: FETCH_PRODUCT_LIST,
    payload: data,
  }
);

export const fetchProductListSuccess = (data) => ({
  type: FETCH_PRODUCT_LIST_SUCCESS,
  payload: data,
});

export const fetchProductListFailure = (error) => ({
  type: FETCH_PRODUCT_LIST_FAILURE,
  payload: error,
});

// similar product
export const fetchSimilarProduct = (data) => ({
  type: FETCH_SIMILAR_PRODUCT,
  payload: data,
});

export const fetchSimilarProductSuccess = (data) => ({
  type: FETCH_SIMILAR_PRODUCT_SUCCESS,
  payload: data,
});

export const fetchSimilarProductFailure = (error) => ({
  type: FETCH_SIMILAR_PRODUCT_FAILURE,
  payload: error,
});

// valid cookie
export const fetchCookie = (data) => ({
  type: FETCH_COOKIE,
  payload: data,
});
export const fetchCookieSuccess = (data) => ({
  type: FETCH_COOKIE_SUCCESS,
  payload: data,
});
export const fetchCookieFailure = (data) => ({
  type: FETCH_COOKIE_FAILURE,
  payload: data,
});

// valid uniqueId
export const fetchUniqueId = (data) => ({
  type: FETCH_UNIQUEID,
  payload: data,
});
export const fetchUniqueIdSuccess = (data) => ({
  type: FETCH_UNIQUEID_SUCCESS,
  payload: data,
});
export const fetchUniqueIdFailure = (data) => ({
  type: FETCH_UNIQUEID_FAILURE,
  payload: data,
});

// fetch wishList
export const fetchWishList = (data) => (
  console.log("fetchWishListid", data),
  {
    type: FETCH_WISHLIST,
    payload: data,
  }
);
export const fetchWishListSuccess = (data) => ({
  type: FETCH_WISHLIST_SUCCESS,
  payload: data,
});
export const fetchWishListFailure = (data) => ({
  type: FETCH_WISHLIST_FAILURE,
  payload: data,
});

// save to  wishList
export const saveWishList = (data) => ({
  type: SAVE_WISHLIST,
  payload: data,
});
export const saveWishListSuccess = (data) => ({
  type: SAVE_WISHLIST_SUCCESS,
  payload: data,
});
export const saveWishListFailure = (data) => ({
  type: SAVE_WISHLIST_FAILURE,
  payload: data,
});

export const resetSavedWishList = (data) => ({
  type: RESET_SAVED_WISHLIST,
});

// get storeList
export const getStores = (data) => ({
  type: GET_STORELIST,
  payload: data,
});
export const getStoresSuccess = (data) => ({
  type: GET_STORELIST_SUCCESS,
  payload: data,
});
export const getStoresFailure = (data) => ({
  type: GET_STORELIST_FAILURE,
  payload: data,
});

// add store
export const saveFavStore = (data) => ({
  type: ADD_FAVSTORE,
  payload: data,
});
export const addFavStoreSuccess = (data) => ({
  type: ADD_FAVSTORE_SUCCESS,
  payload: data,
});
export const addFavStoreFailure = (data) => ({
  type: ADD_FAVSTORE_FAILURE,
  payload: data,
});
export const resetAddFavStore = (data) => ({
  type: RESET_ADD_FAVSTORE,
  payload: data,
});

// getfav store
export const fetchFavStores = (data) => ({
  type: FETCH_FAVSTORE,
  payload: data,
});
export const fetchFavStoresSuccess = (data) => ({
  type: FETCH_FAVSTORE_SUCCESS,
  payload: data,
});
export const fetchFavStoresFailure = (data) => ({
  type: FETCH_FAVSTORE_FAILURE,
  payload: data,
});

// create list
export const createList = (data) => ({
  type: CREATE_LIST,
  payload: data,
});
export const createListSuccess = (data) => ({
  type: CREATE_LIST_SUCCESS,
  payload: data,
});
export const createListFailure = (data) => ({
  type: CREATE_LIST_FAILURE,
  payload: data,
});

// fetch list
export const fetchList = (data) => ({
  type: FETCH_LIST,
  payload: data,
});
export const fetchListSuccess = (data) => ({
  type: FETCH_LIST_SUCCESS,
  payload: data,
});
export const fetchListFailure = (data) => ({
  type: FETCH_LIST_FAILURE,
  payload: data,
});

// Remove fav store
export const deleteList = (data) => ({
  type: DELETE_LIST,
  payload: data,
});
export const deleteListSuccess = (data) => ({
  type: DELETE_LIST_SUCCESS,
  payload: data,
});
export const deleteListFailure = (data) => ({
  type: DELETE_LIST_FAILURE,
  payload: data,
});
export const resetDeleteList = (data) => ({
  type: RESET_DELETE_LIST,
});

// delete  list
export const removeFavStore = (data) => ({
  type: REMOVE_FAV_STORE,
  payload: data,
});
export const removeFavStoreSuccess = (data) => ({
  type: REMOVE_FAV_STORE_SUCCESS,
  payload: data,
});
export const removeFavStoreFailure = (data) => ({
  type: REMOVE_FAV_STORE_FAILURE,
  payload: data,
});

export const resetRemoveFavStore = (data) => ({
  type: RESET_REMOVE_FAV_STORE,
});

// delete  list
export const addUpdateList = (data) => ({
  type: ADDUPDATE_LIST,
  payload: data,
});
export const addUpdateListSuccess = (data) => ({
  type: ADDUPDATE_LIST_SUCCESS,
  payload: data,
});
export const addUpdateListFailure = (data) => ({
  type: ADDUPDATE_LIST_FAILURE,
  payload: data,
});
export const resetAddUpdateList = (data) => ({
  type: RESET_ADDUPDATE_LIST,
});

// add list
export const addToList = (data) => ({
  type: ADD_TO_LIST,
  payload: data,
});
export const addToListSuccess = (data) => ({
  type: ADD_TO_LIST_SUCCESS,
  payload: data,
});
export const addToListFailure = (data) => ({
  type: ADD_TO_LIST_FAILURE,
  payload: data,
});
export const resetAddToList = (data) => ({
  type: RESET_ADD_TO_LIST,
});

// addupdate notifcatioin
export const addUpdateNotification = (data) => ({
  type: ADD_UPDATE_NOTIFICATION,
  payload: data,
});
export const addUpdateNotificationSuccess = (data) => ({
  type: ADD_UPDATE_NOTIFICATION_SUCCESS,
  payload: data,
});
export const addUpdateNotificationFailure = (data) => ({
  type: ADD_UPDATE_NOTIFICATION_FAILURE,
  payload: data,
});

export const resetAddUpdateNotification = (data) => ({
  type: RESET_ADD_UPDATE_NOTIFICATION,
  payload: data,
});

// getSimilarProducts
export const getSimilarProducts = (data) => ({
  type: GET_SIMILAR_PRODUCTS,
  payload: data,
});
export const getSimilarProductsSuccess = (data) => ({
  type: GET_SIMILAR_PRODUCTS_SUCCESS,
  payload: data,
});
export const getSimilarProductsFailure = (data) => ({
  type: GET_SIMILAR_PRODUCTS_FAILURE,
  payload: data,
});

// getNotifications
export const getNotifications = (data) => ({
  type: GET_NOTIFICATIONS,
  payload: data,
});
export const getNotificationsSuccess = (data) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: data,
});
export const getNotificationsFailure = (data) => ({
  type: GET_NOTIFICATIONS_FAILURE,
  payload: data,
});

// markAsRead
export const markAsRead = (data) => ({
  type: MARK_AS_READ,
  payload: data,
});
export const markAsReadSuccess = (data) => ({
  type: MARK_AS_READ_SUCCESS,
  payload: data,
});
export const markAsReadFailure = (data) => ({
  type: MARK_AS_READ_FAILURE,
  payload: data,
});

// delete product
export const deleteProduct = (data) => ({
  type: DELETE_PRODUCTS,
  payload: data,
});
export const deleteProductSuccess = (data) => ({
  type: DELETE_PRODUCTS_SUCCESS,
  payload: data,
});
export const deleteProductFailure = (data) => ({
  type: DELETE_PRODUCTS_FAILURE,
  payload: data,
});

// edit settings

export const editSettings = (data) => ({
  type: EDIT_SETTINGS,
  payload: data,
});
export const editSettingsSuccess = (data) => ({
  type: EDIT_SETTINGS_SUCCESS,
  payload: data,
});
export const editSettingsFailure = (data) => ({
  type: EDIT_SETTINGS_FAILURE,
  payload: data,
});

// price evolution

export const priceEvolution = (data) => ({
  type: PRICE_EVOLUTION,
  payload: data,
});
export const priceEvolutionSuccess = (data) => ({
  type: PRICE_EVOLUTION_SUCCESS,
  payload: data,
});
export const priceEvolutionFailure = (data) => ({
  type: PRICE_EVOLUTION_FAILURE,
  payload: data,
});

// delete fnotifaction
export const deleteNotification = (data) => (
  console.log("deleteNotification", deleteNotification),
  {
    type: DELETE_NOTIFICATION,
    payload: data,
  }
);

export const deleteNotificationSuccess = (data) => (
  console.log("deleteNotificationSuccess", deleteNotificationSuccess),
  {
    type: DELETE_NOTIFICATION_SUCCESS,
    payload: data,
  }
);
export const deleteNotificationFailure = (data) => ({
  type: DELETE_NOTIFICATION_FAILURE,
  payload: data,
});

export const resetDeleteNotification = () => ({
  type: RESET_DELETE_NOTIFICATION,
});

// get product Review
export const getProductReviews = (data) => ({
  type: PRODUCT_REVIEWS,
  payload: data,
});
export const getProductReviewsSuccess = (data) => ({
  type: PRODUCT_REVIEWS_SUCCESS,
  payload: data,
});
export const getProductReviewsFailure = (data) => ({
  type: PRODUCT_REVIEWS_FAILURE,
  payload: data,
});

export const fetchPriceHistoryRequest = () => ({
  type: FETCH_PRICE_HISTORY_REQUEST,
});

export const fetchPriceHistorySuccess = (data) => ({
  type: FETCH_PRICE_HISTORY_SUCCESS,
  payload: data,
});

export const fetchPriceHistoryFailure = (error) => ({
  type: FETCH_PRICE_HISTORY_FAILURE,
  payload: error,
});
