import React, { useEffect, useState } from "react";
import ProductListContainer from "./productListContainer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CreateNewList from "./createNewList";
import { buildUrl, getStoreName, handleRedirect } from "./BuildUrl";
import useUniqueId from "./useUniqueIdHook";
import RecentlyViewedCard from "./recentlyViewedCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchList, resetAddUpdateList } from "./actions";
import { useSaveToWishList, useUpdateNotification } from "./SaveWishListHook";
import DeleteSavedDeals from "./deleteSavedDeals";
import useGetWishList from "./getWishListHook";
import { type } from "@testing-library/user-event/dist/type";
import { useEditSettings } from "./ThreeDotsApiCallsHook";
import EditList from "./EditListComponent";
import SingleProductDisplay from "./singleProductComponent";
import { showToast } from "./Toast";

const AddComponent = ({
  storedData,
  isCheckoutMode,
  activeProductData,
  domain,
}) => {
  const [itemInfoSection, setItemInfoSection] = useState("0");
  const [singleProduct, setSingleProduct] = useState(activeProductData);
  const [saveListid, setSaveListId] = useState("");
  const [saveList, setSaveList] = useState([]);
  const [itemSaved, setItemSaved] = useState(false);
  const [suggestedList, setSuggestedList] = useState([
    { name: "Favourates", desc: "" },
  ]);
  const [recentData, setRecentData] = useState([]);
  const [showSavePoint, setShowSavePoint] = useState(false);
  const [openCreateBox, setOpenCreateBox] = useState(false);
  const [addRemoveSavedProduct, setAddRemoveSavedProduct] = useState(false);

  const dispatch = useDispatch();
  const fetchListData = useSelector((state) => state.fetchListData);
  const fetchListError = useSelector((state) => state.fetchListError);
  const loadingfetchList = useSelector((state) => state.loadingfetchList);

  const { uniqueIdData } = useUniqueId();
  const [uuid, setUuid] = useState("");
  // const platform =getStoreName(domain)
  console.log("activeProductData", activeProductData);

  console.log("isCheckoutMode345", isCheckoutMode);
  useEffect(() => {
    if (uniqueIdData && uniqueIdData.user_id) {
      console.log("uniqueIdData ", uniqueIdData.user_id);
      setUuid(uniqueIdData.user_id);
    }
  }, [uniqueIdData]);

  // useEffect(() => {
  //   console.log("notify345", JSON.stringify([]));
  //   // localStorage.setItem("selectedLists", JSON.stringify([]));
  //   localStorage.setItem("notificationId", JSON.stringify([]));
  // }, []);

  const { savedeals } = useSaveToWishList();
  const { savedWishListData, handleSaveToWishList } = useSaveToWishList();
  // const platform =getStoreName(domain)

  useEffect(() => {
    if (uniqueIdData && uniqueIdData.user_id) {
      console.log("uniqueIdData ", uniqueIdData.user_id);
      setUuid(uniqueIdData.user_id);
    }
  }, [uniqueIdData]);

  useEffect(() => {
    if (uniqueIdData && uniqueIdData.user_id) {
      console.log("uniqueIdData ", uniqueIdData.user_id);
      setUuid(uniqueIdData.user_id);
    }
  }, [uniqueIdData]);

  const getProductUrl = (url) => {
    // const dealUrl = buildUrl(uuid, url, platform);
    console.log("domain ", domain);
    handleRedirect(url, "", true, domain);
  };

  const onListSelect = (id) => {
    if (id != "") {
      setSaveListId(id);
      console.log("selected saveListid", id);
      console.log("saveListid ", saveListid);
    }
  };

  const openCreatNewListBox = () => {
    setOpenCreateBox(true);
  };
  const closeCreatListBox = () => {
    setOpenCreateBox(false);
  };

  const { addUpdateNotificationData, updateNotifcation } =
    useUpdateNotification();
  const addUpdateNotification = (data) => {
    updateNotifcation(data);
  };

  // update the list in which wil lsaved fav product
  const updateProductList = (listObj) => {
    console.log("listObj ", listObj);
  };

  useEffect(() => {
    setSaveList(setSaveList(fetchListData.data));
  }, [fetchListData]);

  //  let singleProduct = activeProductData;
  console.log(activeProductData);
  const title = singleProduct.product_title;
  // Split the title into an array of words using split() method
  const wordsArray = title ? title.split(" ") : "";
  // Get the first word from the array
  const firstWord = wordsArray[0];

  const saveProduct = async (data, index) => {
    console.log("data222", data);
    setAddRemoveSavedProduct(addRemoveSavedProduct == index ? null : index);
    setItemSaved(true);

    if (Object.keys(data).length != 0) {
      let obj = {
        product_title: data.product_title,
        product_category: data.breadcrumb,
        product_image: data.imgSrc,
        product_price: data.price,
        product_url: data.product_url,
        product_org_price: data.originalPrice,
        list_id: localStorage.getItem("selectedLists")
          ? JSON.parse(localStorage.getItem("selectedLists")).join(",")
          : "",
        //list_id: saveListid,
        uuid_type: "deal",
        uuid: "",
        type: alreadySaved ? "UPDATE" : "ADD",
        notification_id: localStorage.getItem("notificationId")
          ? JSON.parse(localStorage.getItem("notificationId"))
          : "",
      };

      console.log("handleSaveToWishList55", handleSaveToWishList);
      handleSaveToWishList(obj);
    }
  };

  console.log("isCheckoutMode ", isCheckoutMode);

  useEffect(() => {
    // if (CouponData && domain) {
    // Add event listener to receive messages from the plugin
    const parentWindow = window.parent;
    console.log(domain);
    const startTime = performance.now();
    parentWindow.postMessage(
      { action: "recentUserHistory", startTime },
      domain
    );
  }, [domain]);

  window.addEventListener("message", (event) => {
    const receivedData = event.data;

    // console.log(receivedData);
    if (receivedData.action == "recentHistoryData") {
      const endTime = performance.now();
      const totalElapsedTime = endTime - event.data.startTime;
      const steps = event.data.steps;
      console.log(
        `Total time taken for round trip: ${totalElapsedTime.toFixed(2)} ms`
      );
      console.log(`Steps timings:`, steps);

      const uniqueTitles = Array.from(
        new Set(receivedData.data.map((item) => item.product_title))
      );
      console.log("receivedData", receivedData);
      // Map unique URLs to their respective objects
      const uniqueProducts = uniqueTitles.map((title) =>
        receivedData.data.find((item) => item.product_title == title)
      );
      console.log("uniqueProducts", uniqueProducts);
      setRecentData(uniqueProducts);
    }
  });

  const [saveItem, setSaveItem] = useState([]);
  useEffect(() => {
    console.log(recentData);
    if (recentData.length > 0) {
      // setSaveItem(new Array(recentData.length).fill(false));
      setSaveItem(
        recentData.map((deal) =>
          deal.hasOwnProperty("wishlist_status") ? deal.wishlist_status : false
        )
      );
    }
  }, [recentData]);

  const saveDeal = (deal, index) => {
    console.log("archana", deal);
    let obj = {
      product_title: deal.product_title,
      product_category: "",
      product_image: deal.imgSrc,
      product_price: deal.price,
      product_url: deal.product_url,
      product_org_price: deal.deal_original_price,
      uuid_type: "deal",
      uuid: "",
      type: "ADD",
    };
    handleSaveToWishList(obj);
    console.log("savedWishListData ", savedWishListData);
    toggleSaveItem(index);
  };
  const toggleSaveItem = (index) => {
    setSaveItem((prevSaveItem) => {
      const newSaveItem = [...prevSaveItem];
      newSaveItem[index] = !newSaveItem[index];
      return newSaveItem;
    });
  };
  console.log("saveItem ", saveItem);

  // on adding list get updated list
  const addUpdateData = useSelector((state) => state.addUpdateData);
  useEffect(() => {
    console.log("addUpdateData", addUpdateData);
    if (addUpdateData && addUpdateData.status) {
      showToast("success", addUpdateData.message);
      dispatch(resetAddUpdateList());
      dispatch(fetchList({}));
    }
  }, [addUpdateData]);

  useEffect(() => {
    dispatch(fetchList({}));
  }, []);

  const [isDelete, setIsDelete] = useState(false);
  const [productToDelete, setProductToDelete] = useState({});
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const removeDeal = (baseUrl, deal, index) => {
    console.log("delete deal ", deal);
    // let product_img = baseUrl + deal.deal_image;
    let obj = {
      product_title: deal.product_title,
      product_category: "",
      product_image: deal.product_image,
      product_price: deal.product_price,
      product_url: deal.product_url,
      uuid_type: "deal",
      uuid: deal.uuid,
      type: "REMOVE",
    };
    let productObj = {
      index: index,
      obj: obj,
    };
    setProductToDelete(productObj);
    setIsDeletePopup(true);
  };
  useEffect(() => {
    console.log("isDelete ", isDelete);
    if (isDelete == true) {
      handleSaveToWishList(productToDelete.obj);
      setIsDelete(false);
    }

    if (savedWishListData.message == "Removed from wishlist") {
      handleGetToWishList();
      toggleSaveItem(productToDelete.index);
      setIsDeletePopup(false);
    } else {
    }
  }, [isDelete, savedWishListData]);

  const { wishListData, loadingWishList, handleGetToWishList, wishListError } =
    useGetWishList();
  const { editSettingsData, getNotificationsOptions } = useEditSettings();

  const [recentDataState, setRecentDataState] = useState(recentData);
  const [isProcessed, setIsProcessed] = useState(false);
  useEffect(() => {
    handleGetToWishList();
    getNotificationsOptions({ type: "PRODUCT" });
  }, []);

  useEffect(() => {
    if (
      !isProcessed &&
      wishListData?.data?.length > 0 &&
      recentData?.length > 0
    ) {
      const updatedRecentData = recentData.map((recent) => {
        const savedMatch = wishListData.data.find(
          (saved) => saved.product_url === recent.product_url
        );
        return savedMatch ? { ...recent, ...savedMatch } : recent;
      });

      console.log("updatedRecentData", updatedRecentData);
      setSaveItem(
        updatedRecentData.map((deal) =>
          deal.hasOwnProperty("wishlist_status") ? deal.wishlist_status : false
        )
      );
      setRecentData(updatedRecentData);
      setIsProcessed(true);
    }
  }, [recentData, wishListData, isProcessed]);

  const [muteUpdate, setMuteUpdate] = useState(false);
  const [alreadySaved, setAlreadySaved] = useState(false);
  useEffect(() => {
    if (wishListData && wishListData.data && wishListData.data.length > 0) {
      let alreadySaved = wishListData.data.find(
        (item) => item.product_title === activeProductData.product_title
      );

      setAlreadySaved(alreadySaved);

      if (alreadySaved != undefined) {
        alreadySaved != undefined && alreadySaved.is_mute_notification == 0
          ? setMuteUpdate(true)
          : setMuteUpdate(false);
        if (alreadySaved.notification_option_id)
          localStorage.setItem(
            "notificationId",
            JSON.stringify(alreadySaved.notification_option_id)
          );
        if (alreadySaved.list_id) {
          // Split the string by commas, filter out any empty values, and convert to integers
          const selectedListsArray = alreadySaved.list_id
            .split(",")
            .filter(Boolean) // Filter out empty values
            .map(Number); // Convert strings to numbers

          // Store the array in local storage as a JSON string
          localStorage.setItem(
            "selectedLists",
            JSON.stringify(selectedListsArray)
          );
        } else {
          localStorage.setItem("selectedLists", []);
        }

        const productListIds =
          alreadySaved.list_id != "" ? alreadySaved.list_id : "";
        console.log("alreadySaved", productListIds);
        onListSelect(productListIds);
      }
    }
  }, [wishListData]);

  useEffect(() => {
    if (addUpdateNotificationData) {
      handleGetToWishList();
    }
  }, [addUpdateNotificationData]);

  const updateMutification = () => {
    // setMuteUpdate(true);
    updateNotifcation({
      product_url: alreadySaved.product_url,
      notification_master_id: localStorage.getItem("notificationId")
        ? JSON.parse(localStorage.getItem("notificationId"))
        : "",
      is_mute_notification: !alreadySaved.is_mute_notification * 1,
      type: "SYSTEM",
    });
  };

  // edit section
  const [editBox, setEditBox] = useState(false);
  const [createdList, setCreateList] = useState([]);

  useEffect(() => {
    // Set background colors based on saved selected lists and fetched data
    if (fetchListData && fetchListData.data) {
      const allLists = fetchListData.data;

      let myList = allLists.filter((list) => list.created_by != null);
      setCreateList(myList);
      console.log("createdList ", createdList);
    }
  }, [fetchListData]);

  const closeEditListBox = () => {
    setEditBox(false);
  };

  const openEditListBox = () => {
    dispatch(fetchList({}));
    setEditBox(true);
  };

  return (
    <>
      <>
        {/* {isCheckoutMode == "checkout" ? ( */}
        {isCheckoutMode == "" || isCheckoutMode == "checkout" ? (
          // {/* {false ? ( */}
          <>
            <div
              className="addContainer"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <ul className="addInfo">
                <i
                  class="fa-regular fa-heart"
                  style={{
                    verticalAlign: "middle",
                    minWidth: "45PX",
                    minHeight: "45PX",
                    maxWidth: "45PX",
                    maxHeight: "45PX",
                  }}
                ></i>
                <li>
                  <p>Stuff worth saving goes here</p>
                </li>
                <li>
                  <p>Not sure where to start?</p>
                </li>
                <li
                  onClick={() => {
                    setShowSavePoint(true);
                  }}
                >
                  <p>see how to save</p>
                </li>
              </ul>
              {/* line */}
              <h3>Recently Viewed</h3>
              <ul className="notificationCard">
                {recentData.slice(0, 10).map((product, index) => (
                  <li key={index}>
                    <img
                      src={product.imgSrc}
                      alt={product.title}
                      onClick={() => getProductUrl(product.product_url)}
                    />

                    <div
                      onClick={() => getProductUrl(product.product_url)}
                      style={{
                        padding: "0px 10px",
                      }}
                    >
                      <div className="price">
                        <span>₹ {product.price}</span>{" "}
                        <span className="line">0</span>
                      </div>
                      <div>
                        <p className="title">{product.product_title}</p>
                      </div>
                    </div>
                    <>
                      <span
                        className="discount saveDeal"
                        onClick={() => {
                          removeDeal(product.product_url, product, index);
                        }}
                        style={{
                          display: saveItem[index] ? "block" : "none",
                          marginLeft: "-20px",
                          top: "56px",
                        }}
                      >
                        <i
                          className="fa-solid fa-heart"
                          style={{
                            width: "57px",
                            position: "absolute",
                            marginTop: "-49px",
                            left: "71%",
                            color: "#9D4EDD",
                            fontSize: "18px",
                          }}
                        ></i>
                      </span>
                      <span
                        className="discount saveDeal"
                        onClick={() => {
                          saveDeal(product, index);
                        }}
                        style={{
                          display: !saveItem[index] ? "block" : "none",
                          marginLeft: "-20px",
                          top: "56px",
                        }}
                      >
                        <i
                          className="fa-regular fa-heart"
                          style={{
                            width: "57px",
                            position: "absolute",
                            marginTop: "-49px",
                            left: "71%",
                            color: "#9D4EDD",
                            fontSize: "18px",
                          }}
                        ></i>
                      </span>
                    </>
                  </li>
                ))}
              </ul>
              {/* {true ? ( */}
              //{" "}
              {showSavePoint ? (
                <div className="filterOverlay">
                  <div className="itemSavingPoints">
                    <div className="model-header">
                      <h2 style={{ padding: "0px" }}>
                        <i className="fa-regular fa-heart"></i> Save your
                        favorite items
                      </h2>

                      <div
                        className="model-header-icon"
                        onClick={() => {
                          setShowSavePoint(false);
                        }}
                      >
                        <i
                          className="fa fa-close"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    </div>

                    <ul
                      style={{
                        listStyleType: "disc", // Bullet point style
                        paddingLeft: "20px", // Indentation for alignment
                        marginTop: "10px", // Space above the list
                      }}
                    >
                      <li
                        style={{
                          marginBottom: "8px",
                          fontSize: "16px",
                          lineHeight: "1.5",
                        }}
                      >
                        Go to a specific item on a retailer’s site
                      </li>
                      <li
                        style={{
                          marginBottom: "8px",
                          fontSize: "16px",
                          lineHeight: "1.5",
                        }}
                      >
                        Deals2Buy extension will pop up
                      </li>
                      <li
                        style={{
                          marginBottom: "8px",
                          fontSize: "16px",
                          lineHeight: "1.5",
                        }}
                      >
                        Save item to your wishlist
                      </li>
                      <li
                        style={{
                          marginBottom: "8px",
                          fontSize: "16px",
                          lineHeight: "1.5",
                        }}
                      >
                        Sit back and wait for smart updates
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            {isDeletePopup == true ? (
              <DeleteSavedDeals
                setIsDelete={setIsDelete}
                setIsDeletePopup={setIsDeletePopup}
                message={"product"}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            {/* {true ? ( */}
            {!itemSaved ? (
              <>
                <div
                  className="addContainer"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "initial",
                    paddingBottom: "80px",
                  }}
                >
                  <SingleProductDisplay singleProduct={singleProduct} />
                  <ul className="itemDeals">
                    <li onClick={() => setItemInfoSection("0")}>Manage Item</li>
                    <li
                      style={{
                        color: "rgb(158, 154, 154)",
                        pointerEvents: "none",
                      }}
                    >
                      Compare offers
                    </li>
                    <li
                      style={{
                        color: "rgb(158, 154, 154)",
                        pointerEvents: "none",
                      }}
                    >
                      Price trend
                    </li>
                  </ul>
                  <div
                    className="listContainer"
                    style={{
                      display: itemInfoSection == "0" ? "flex" : "none",
                    }}
                  >
                    <ProductListContainer
                      list={suggestedList}
                      onListSelect={onListSelect}
                      openCreatNewListBox={openCreatNewListBox}
                      title="suggestedList"
                      createdList={setCreateList}
                      setEditBox={setEditBox}
                      section={"add"}
                    />
                    {openCreateBox ? (
                      <CreateNewList
                        updateProductList={updateProductList}
                        closeCreatList={closeCreatListBox}
                      />
                    ) : (
                      <></>
                    )}

                    {editBox == true ? (
                      <div
                        className="filterOverlay"
                        style={{
                          display: editBox ? "flex" : "none",
                          height: "100vh",
                        }}
                      >
                        <EditList
                          editListData={saveList}
                          listData={createdList}
                          closeEditListBox={closeEditListBox}
                          height={"auto"}
                          openEditListBox={openEditListBox}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <hr style={{ marginBottom: "10px" }} />

                    <h3>Notify when</h3>
                    <ProductListContainer
                      saveList={editSettingsData}
                      onListSelect={onListSelect}
                      openCreatNewListBox={addUpdateNotification}
                      title="notificationOptions"
                      disabled={muteUpdate}
                      createdList={setCreateList}
                      section={"add"}

                      //  setEditBox={setEditBox}
                    />
                    <span
                      onClick={updateMutification}
                      style={{
                        marginBottom: "15px",
                        marginTop: "-10px",
                        fontSize: "12px",
                        display: "flex",
                        fontWeight: "600",
                        textDecoration: "underline",
                        lineHeight: "11px",
                        gap: "3px",
                        cursor: "pointer",
                        display:
                          alreadySaved != undefined && muteUpdate == true
                            ? "flex"
                            : "none",
                      }}
                    >
                      <i
                        class="fa fa-bell"
                        aria-hidden="true"
                        style={{ display: "block" }}
                      ></i>
                      <span>Unmute this item</span>
                    </span>

                    <span
                      onClick={updateMutification}
                      style={{
                        marginTop: "-10px",
                        fontSize: "12px",
                        display: "flex",
                        fontWeight: "600",
                        textDecoration: "underline",
                        lineHeight: "11px",
                        gap: "3px",
                        cursor: "pointer",
                        display:
                          alreadySaved != undefined && muteUpdate == false
                            ? "block"
                            : "none",
                      }}
                    >
                      <i class="fa-solid fa-bell-slash" aria-hidden="true"></i>
                      <span>Mute this item</span>
                    </span>

                    {/* <h3>Color</h3> */}
                    {/* <ProductListContainer /> */}
                  </div>
                  <div
                    className="listContainer"
                    style={{
                      display: itemInfoSection == "1" ? "flex" : "none",
                    }}
                  >
                    two
                  </div>
                  <div
                    className="listContainer"
                    style={{
                      display: itemInfoSection == "2" ? "flex" : "none",
                    }}
                  >
                    three
                  </div>
                </div>

                <div className="stickyButtonContainer">
                  <button
                    id="saveProduct"
                    onClick={() => {
                      saveProduct(singleProduct);
                    }}
                  >
                    {alreadySaved ? "Update" : "Save"}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="itemSavedSection">
                  <i class="fa-solid fa-check"></i>
                  <h3>
                    {" "}
                    {alreadySaved != undefined
                      ? " Item Updated"
                      : " Item Saved"}{" "}
                  </h3>
                </div>
              </>
            )}
          </>
        )}
      </>
    </>
  );
};

export default AddComponent;
