import React, { useState, useEffect } from "react";
import NoActiveDeals from "./noActiveDeals";
import { buildUrl, getStoreName, handleRedirect } from "./BuildUrl";
import useUniqueId from "./useUniqueIdHook";
import { getDealsUrl } from "./BuildUrl";

const CouponsList = ({
  coupons,
  domain,
  copyToClipboard,
  setCouponData,
  handleSeeAllClick,
  storeName,
  seeAll,
  padding,
  couponsColor,
}) => {
  const { uniqueIdData } = useUniqueId();
  const [uuid, setUuid] = useState("");
  const [couponsData, setCouponsData] = useState(coupons);
  const [seeAllbtn, setSeeAll] = useState(true);
  const [showCouponCode, setShowCouponCode] = useState([]);

  useEffect(() => {
    if (coupons != undefined) {
      setCouponsData(coupons.slice(0, 5));
      setShowCouponCode(Array(coupons ? coupons.length : 0).fill(false));
    }
  }, [coupons]);

  useEffect(() => {
    if (seeAllbtn && coupons) {
      setCouponsData(coupons.slice(0, 5));
    } else {
      setCouponsData(coupons);
    }
    setSeeAll(seeAll);
  }, [seeAll, coupons, seeAllbtn]);

  useEffect(() => {
    if (uniqueIdData && uniqueIdData.user_id) {
      setUuid(uniqueIdData.user_id);
    }
  }, [uniqueIdData]);

  const toggleCouponCode = (index) => {
    setShowCouponCode((prevCouponCode) => {
      const newCouponCode = [...prevCouponCode];
      newCouponCode[index] = !newCouponCode[index];
      return newCouponCode;
    });
  };

  const getCouponUrl = (index, url, platform, storeId) => {
    toggleCouponCode(index);
    copyToClipboard(index, coupons);

    let HTMLencodedUrl = encodeURIComponent(url);
    const dealUrl = buildUrl(uuid, HTMLencodedUrl, platform, storeId);

    const parentWindow = window.parent;
    parentWindow.postMessage(
      { action: "redirectToStore", storeUrl: dealUrl, redirectTab: false },
      domain
    );
  };

  const handleSeeAll = () => {
    handleSeeAllClick(coupons, storeName, copyToClipboard);
    setCouponData(coupons);
  };

  console.log("domain ", domain);
  return (
    <>
      <ul className="favStoreCoupons">
        {
          couponsData &&
            couponsData.length > 0 &&
            couponsData.map((item, index) => (
              <li
                key={index}
                style={{ background: couponsColor ? couponsColor : "white" }}
              >
                <div className="couponInfo">
                  <p
                    className="storeName"
                    style={{ padding: padding ? padding : "" }}
                  >
                    {item.store_name}
                  </p>
                  <div className="text">{item.coupon_title}</div>
                </div>
                <div className="couponInfo">
                  <div className="text">
                    Worked {item.coupon_users_redeemed} days ago
                  </div>
                  <div className="couponBtns">
                    <button
                      data-alooma-section="retailer"
                      data-alooma-source="coupons"
                      data-alooma-element="get_coupon"
                      onClick={() => {
                        getCouponUrl(
                          index,
                          item.coupon_url,
                          item.store_name,
                          item.store_id
                        );
                      }}
                      style={{
                        display: !showCouponCode[index] ? "block" : "none",
                      }}
                    >
                      Get Coupon
                    </button>
                    <button
                      data-alooma-section="retailer"
                      data-alooma-source="coupons"
                      data-alooma-element="get_coupon"
                      style={{
                        display: showCouponCode[index] ? "block" : "none",
                        color: "gray",
                      }}
                    >
                      {item.coupon_code}
                    </button>
                    <button
                      data-alooma-section="retailer"
                      data-alooma-source="coupons"
                      data-alooma-element="go_to_store"
                      onClick={() => {
                        getDealsUrl(item.store_url, item.store_id, domain);
                      }}
                    >
                      Go to store
                    </button>
                  </div>
                </div>
              </li>
            ))
          // : (
          //   <NoActiveDeals storeName={storeName} />
          // )
        }
      </ul>
      {coupons && coupons.length > 0 && seeAllbtn ? (
        <span
          style={{
            cursor: "pointer",
            background: "none",
            marginTop: "-6px",
            fontSize: "12px",
          }}
          onClick={handleSeeAll}
        >
          See All
        </span>
      ) : null}
    </>
  );
};

export default CouponsList;
