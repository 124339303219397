import React, { useEffect, useState } from "react";
import CreateNewList from "./createNewList";
import { useDispatch, useSelector } from "react-redux";
import { fetchList } from "./actions";

const ProductListContainer = ({
  saveList,
  onListSelect,
  openCreatNewListBox,
  title,
  disabled,
  createdList,
  setEditBox,
  section,
}) => {
  console.log("disabled ", disabled);
  const [savelistBox, setSaveListBox] = useState(false);
  const [list, setList] = useState(saveList);
  const [suggestedList, setsuggestedList] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState([]);
  const [selectedLists, setSelectedLists] = useState([]);
  console.log("productsList ", selectedLists);

  // const colors = [
  //   "#0d6efd", // Blue
  //   "#6610f2", // Indigo
  //   "#6F42C3", // Purple
  //   "#d63384", // Pink
  //   "#dc3545", // Red
  //   // "#fd7e14", // Orange
  //   "#ffc107", // Yellow
  //   "#198754", // Green
  //   "#20c997", // Teal
  //   "#0dcaf0", // Cyan
  //   // "#000000", // Black
  //   // "#ffffff", // White
  //   "#6c757d", // Gray
  //   "#9a61f8", // Indigo (lighter)
  //   "#e87685", // Red (lighter)
  // ];

  const colors = [
    "#0d6efd", // Blue
    "#6610f2", // Indigo
    "#6f42c1", // Purple
    "#d63384", // Pink
    "#dc3545", // Red
    "#fd7e14", // Orange
    "#ffc107", // Yellow
    "#198754", // Green
    "#20c997", // Teal
    "#0dcaf0", // Cyan
  ];

  const dispatch = useDispatch();

  const fetchListData = useSelector((state) => state.fetchListData);
  const fetchListError = useSelector((state) => state.fetchListError);
  const loadingfetchList = useSelector((state) => state.loadingfetchList);

  useEffect(() => {
    dispatch(fetchList({}));
  }, [dispatch]);

  useEffect(() => {
    setList(saveList);
  }, [saveList]);

  useEffect(() => {
    // Load selected lists from local storage when the component mounts
    let savedSelectedLists = [];
    console.log("section ", section);
    if (section == "saved") {
      savedSelectedLists = localStorage.getItem("savedSelectedLists")
        ? JSON.parse(localStorage.getItem("savedSelectedLists"))
        : [];
      setSelectedLists(savedSelectedLists);
    } else {
      savedSelectedLists = localStorage.getItem("selectedLists")
        ? JSON.parse(localStorage.getItem("selectedLists"))
        : [];
      setSelectedLists(savedSelectedLists);
    }

    // Set background colors based on saved selected lists and fetched data
    if (fetchListData && fetchListData.data) {
      const allLists = fetchListData.data;
      const newBackgroundColors = allLists.map((list) =>
        savedSelectedLists.includes(list.list_id)
      );
      setBackgroundColor(newBackgroundColors);

      let myList = allLists.filter((list) => list.created_by != null);
      setList(myList);
      if (createdList != undefined) createdList(myList);
      let suggestedListData = allLists.filter(
        (list) => list.created_by == null
      );
      setsuggestedList(suggestedListData);
    }
  }, [fetchListData]);

  const toggleSaveItem = (listId) => {
    console.log("productsListdemo1", listId);
    //
    let savedSelectedLists;
    if (section == "saved") {
      savedSelectedLists = localStorage.getItem("savedSelectedLists")
        ? JSON.parse(localStorage.getItem("savedSelectedLists"))
        : [];
      console.log("savedSelectedLists4433", savedSelectedLists);
      setSelectedLists(savedSelectedLists);
    } else {
      //savedSelectedLists = [];
      savedSelectedLists = localStorage.getItem("selectedLists")
        ? JSON.parse(localStorage.getItem("selectedLists"))
        : [];
      console.log("savedSelectedLists333", savedSelectedLists);
      setSelectedLists(savedSelectedLists);
    }

    const isSelected = savedSelectedLists.includes(listId);

    if (!isSelected) {
      savedSelectedLists.push(listId);
    } else {
      savedSelectedLists = savedSelectedLists.filter((id) => id !== listId);
    }
    console.log("productsListdemo12", savedSelectedLists);

    if (section == "saved") {
      localStorage.setItem(
        "savedSelectedLists",
        JSON.stringify(savedSelectedLists)
      );
      setSelectedLists(savedSelectedLists);
    } else {
      localStorage.setItem("selectedLists", JSON.stringify(savedSelectedLists));
      setSelectedLists(savedSelectedLists);
    }

    setBackgroundColor((prevBackgroundColors) => {
      const newBackgroundColors = [...prevBackgroundColors];
      const listIndex = fetchListData.data.findIndex(
        (list) => list.list_id === listId
      );
      newBackgroundColors[listIndex] = !isSelected;
      return newBackgroundColors;
    });
  };

  // const toggleSaveItem = (listId) => {
  //   console.log("productsListdemo1", listId);

  //   // Retrieve the saved lists from localStorage or initialize an empty array if not present
  //   let savedSelectedLists;
  //   if (section == "saved") {
  //     savedSelectedLists = localStorage.getItem("savedSelectedLists")
  //       ? JSON.parse(localStorage.getItem("savedSelectedLists"))
  //       : [];
  //     setSelectedLists(savedSelectedLists);
  //   } else {
  //     savedSelectedLists = [...selectedLists];
  //     // savedSelectedLists = localStorage.getItem("selectedLists")
  //     //   ? JSON.parse(localStorage.getItem("selectedLists"))
  //     //   : [];
  //     setSelectedLists(savedSelectedLists);
  //   }
  //   // Check if the listId is already in the saved lists
  //   const isSelected = savedSelectedLists.includes(listId);

  //   if (!isSelected) {
  //     // If not selected, add it to the saved lists
  //     savedSelectedLists.push(listId);
  //   } else {
  //     // If selected, remove it from the saved lists
  //     savedSelectedLists = savedSelectedLists.filter((id) => id !== listId);
  //   }
  //   console.log("productsListdemo12", savedSelectedLists);
  //   // Save the updated selected lists to localStorage
  //   localStorage.setItem(
  //     "savedSelectedLists",
  //     JSON.stringify(savedSelectedLists)
  //   );

  //   // Update setSelectedLists state
  //   setSelectedLists(savedSelectedLists);
  //   // if (section == "saved") {
  //   //   localStorage.setItem(
  //   //     "savedSelectedLists",
  //   //     JSON.stringify(savedSelectedLists)
  //   //   );
  //   //   setSelectedLists(savedSelectedLists);
  //   // } else {
  //   //   // localStorage.setItem("selectedLists", JSON.stringify(savedSelectedLists));
  //   //   setSelectedLists(savedSelectedLists);
  //   // }
  //   //onListSelect(savedSelectedLists.join(","));
  //   // Update background colors based on the selected lists
  //   setBackgroundColor((prevBackgroundColors) => {
  //     const newBackgroundColors = [...prevBackgroundColors];
  //     const listIndex = fetchListData.data.findIndex(
  //       (list) => list.list_id === listId
  //     );
  //     newBackgroundColors[listIndex] = !isSelected; // Toggle the background color based on previous state
  //     return newBackgroundColors;
  //   });
  // };

  useEffect(() => {
    let savedSelectedLists = [];
    console.log("section ", section);
    if (section == "saved") {
      savedSelectedLists = localStorage.getItem("savedSelectedLists")
        ? JSON.parse(localStorage.getItem("savedSelectedLists"))
        : [];
      console.log("savedSelectedLists44", savedSelectedLists);
      setSelectedLists(savedSelectedLists);
    } else {
      console.log("savedSelectedLists4444", savedSelectedLists);
      savedSelectedLists = localStorage.getItem("selectedLists")
        ? JSON.parse(localStorage.getItem("selectedLists"))
        : [];
      setSelectedLists(savedSelectedLists);
    }
  }, []);

  const addNewList = () => {
    setSaveListBox(!savelistBox);
    openCreatNewListBox();
  };

  const toggleListBtn = (id) => {
    toggleSaveItem(id);
  };

  // notification section

  const addNewNotification = () => {
    // Implementation for adding a new notification
  };

  const [selectedNotifications, setSelectedNotifications] = useState(); // Replace with your actual state for selected notifications
  console.log("selectedNotifications", selectedNotifications);
  useEffect(() => {
    const notificationId = localStorage.getItem("notificationId")
      ? JSON.parse(localStorage.getItem("notificationId"))
      : "";
    setSelectedNotifications(notificationId);
  }, [selectedLists, onListSelect]);
  const toggleNotification = (notification_id) => {
    // Implementation for toggling a notification
    setSelectedNotifications(notification_id);
    localStorage.setItem("notificationId", JSON.stringify(notification_id));
  };

  const badgeClasses = [
    "round-badge-primary",
    // "round-badge-secondary",
    "round-badge-success",
    "round-badge-danger",
    "round-badge-info",
    "round-badge-light",
    "round-badge-dark",
    "round-badge-warning",
  ];

  function unslugAndCapitalize(text) {
    return text
      .replace(/-/g, " ") // Replace dashes with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  }

  return (
    <>
      {setEditBox != undefined ? (
        <h3>
          Add List
          {(list && list.length > 0 && title === "suggestedList") ||
          title == "allList" ? (
            <span
              onClick={() => {
                // EditList(list);
                setEditBox(true);
              }}
            >
              <i
                className="fa-solid fa-pen"
                style={{
                  marginLeft: "5px",
                  fontSize: "13px",
                  cursor: "pointer",
                  // display: list && list.length > 0 ? 'block' : "none",
                }}
              ></i>
            </span>
          ) : (
            <></>
          )}
        </h3>
      ) : (
        ""
      )}
      <ul className="listBtns">
        {title === "suggestedList" || title == "allList" ? (
          <>
            <li
              onClick={addNewList}
              className="addNewBtn"
              style={{ color: "black" }}
            >
              <span
                className="badge round-badge-black"
                style={{
                  transition: "background-color 0.3s ease",
                }}
              >
                + Add New
              </span>
            </li>
            {/* {fetchListData.data !== undefined &&
              fetchListData.data.map((list, index) => (
                <li
                  key={list.list_id}
                  onClick={() => {
                    toggleListBtn(list.list_id);
                  }}
                  className="listItem"
                  style={{
                    color: selectedLists.includes(list.list_id) ? "white" : "",
                    background: selectedLists.includes(list.list_id)
                      ? "rgb(254, 138, 67, 1)"
                      : colors[index % colors.length],
                    transition: "background-color 0.3s ease",
                  }}
                >
                  {list.list_name}
                </li>
              ))} */}

            {fetchListData.data !== undefined &&
              fetchListData.data.map((list, index) => {
                const badgeClass = badgeClasses[index % badgeClasses.length];

                return (
                  <li
                    key={list.list_id}
                    onClick={() => toggleListBtn(list.list_id)}
                    className="listItem"
                    style={{
                      color: selectedLists.includes(list.list_id)
                        ? "white"
                        : "",
                      border: selectedLists.includes(list.list_id)
                        ? "3px solid #fd7e14"
                        : "",
                      transition: "background-color 0.3s ease",
                    }}
                  >
                    <span
                      className={`badge ${badgeClass}`}
                      style={{
                        transition: "background-color 0.3s ease",
                      }}
                    >
                      {unslugAndCapitalize(list.list_name)}
                    </span>
                  </li>
                );
              })}
          </>
        ) : (
          <>
            {title === "notificationOptions" ? (
              <>
                {saveList.notification_options !== undefined &&
                  saveList.notification_options.map((notification, index) => (
                    <li
                      key={notification.notification_master_id}
                      onClick={() => {
                        toggleNotification(notification.notification_master_id);
                      }}
                      //className="notificationItem"
                      className="listItem"
                      style={{
                        color:
                          disabled == false
                            ? selectedNotifications ==
                              notification.notification_master_id
                              ? " white"
                              : "3px solid #fd7e14"
                            : "gray",
                        // background:
                        //   disabled == false
                        //     ? selectedNotifications ==
                        //       notification.notification_id
                        //       ? "#fd7e14"
                        //       : "transparent"
                        //     : "transparent",
                        transition: "background-color 0.3s ease",
                        // pointerEvents: disabled == true ? "none" : "auto",
                        // border:
                        //   disabled == false
                        //     ? selectedNotifications ==
                        //       notification.notification_id
                        //       ? "3px solid #fd7e14"
                        //       : "white"
                        //     : "3px solid #fd7e14",
                        border:
                          selectedNotifications ==
                          notification.notification_master_id
                            ? "3px solid #fd7e14" /* Or any specific color you want */
                            : "",
                      }}
                    >
                      <span
                        className="badge round-badge-black"
                        style={{
                          transition: "background-color 0.3s ease",
                        }}
                      >
                        {" "}
                        {notification.notification_title}
                      </span>
                    </li>
                  ))}
              </>
            ) : (
              <>
                {suggestedList !== undefined &&
                  suggestedList.length > 0 &&
                  suggestedList.map((list, index) => (
                    <li
                      key={list.list_id}
                      onClick={() => {
                        toggleListBtn(list.list_id);
                      }}
                      className="listItem"
                      style={{
                        color: selectedLists.includes(list.list_id)
                          ? "white"
                          : "",
                        background:
                          selectedLists.includes(list.list_id) &&
                          disabled == false
                            ? "rgb(254, 138, 67, 1)"
                            : colors[index % colors.length],
                        transition: "background-color 0.3s ease",
                        pointerEvents: disabled == true ? "none" : "auto",
                      }}
                    >
                      {list.list_name}
                    </li>
                  ))}
              </>
            )}
          </>
        )}
      </ul>
    </>
  );
};

export default ProductListContainer;
