import React, { useEffect, useState } from "react";
import "./App.css";
import CouponsComponent from "./components/Coupons";
import Yourshopping from "./components/Yourshopping";
import { CouponApplyingPopup } from "./components/CouponApplyingPopup";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import IframeTestComponent from "./components/iframeTest";
import SavedComponent from "./components/savedComponent";
import AddComponent from "./components/addComponent";
import RecentlyViewedComponent from "./components/recentlyViewedComponent";
import HeaderComponent from "./components/HeaderComponent";
import NotificationComponent from "./components/NotificationComponent";
import NotificationSettingComponent from "./components/notifcationSettingComponent";
import useGetDeals from "./components/ApisCallsComponent";
import Footer from "./components/footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ActiveTabProvider } from "./components/ActiveTabConntext";
import SeeAllComponent from "./components/seeAllComponent";

import {
  useGetNotification,
  useMarkAsRead,
} from "./components/ThreeDotsApiCallsHook";
import { useDispatch } from "react-redux";
import useGetWishList from "./components/getWishListHook";

import Toast from "./components/Toast";

function App() {
  const [isShopping, setIsShopping] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isViewed, setIsViewed] = useState(false);
  const [domain, setDomain] = useState("");
  const [titlePayloadReq, setTitlePayloadReq] = useState({});
  const [couponPayloadReq, setCouponPayloadReq] = useState({});
  const [isCheckoutMode, setisCheckoutMode] = useState("");
  const [couponApplying, setCouponApplying] = useState(false);
  const [couponsList, setCouponsList] = useState([]);
  const [bestCoupon, setBestCoupon] = useState({});
  const [noData, setNoData] = useState(true);
  const [storedProductData, setStoredProductData] = useState([]);
  const [savedProductData, setSavedProductData] = useState([]);
  const [activeProductData, setactiveProductData] = useState({});
  const [activeTab, setActiveTab] = useState();
  const [title, setTitle] = useState("");
  const [isNotification, setIsNotification] = useState(false);
  const [isItemUpdates, setitemUpdates] = useState(false);
  const [isSeeAll, setIsSeeAll] = useState(false);
  const [couponsData, setCouponsData] = useState(false);
  const [zIndex, setZIndex] = useState(1000);
  const [serverPageType, setServerPageType] = useState();
  const { productList, getDealsData } = useGetDeals();
  const { markAsReadData, markAsReadDataFromServer } = useMarkAsRead();
  const { getNotificationsData, NotificationsDataFromDerver } =
    useGetNotification();
  const { wishListData, loadingWishList, handleGetToWishList, wishListError } =
    useGetWishList();
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetToWishList();
  }, []);

  const notificationTab = () => {
    console.log("notification tab");
    setActiveTab("Notifications");
    setIsNotification(true);
    setIsAdd(false);
    setIsShopping(false);
    setIsSaved(false);
    setIsViewed(false);
    setitemUpdates(false);
    setIsSeeAll(false);

    console.log("notification tab", isNotification);

    markAsReadDataFromServer();
    NotificationsDataFromDerver();
  };
  const itemUpdates = () => {
    console.log("notification tab");
    setActiveTab("Item Updates");
    setitemUpdates(true);
    setIsNotification(false);
    setIsAdd(false);
    setIsShopping(false);
    setIsSaved(false);
    setIsViewed(false);

    console.log("notification tab", isNotification);
  };
  const handleSeeAllClick = (coupons, title) => {
    console.log("handleSeeAllClick ", title);
    console.log("handleSeeAllClick ", coupons);
    if (
      title == "Pouplar Coupons" ||
      title == "Coupons And Deals" ||
      title == "Active Coupons"
    ) {
      setActiveTab(title);
    } else {
      setTitle(title);
    }
    setCouponsData(coupons);
    setIsSeeAll(true);
    setitemUpdates(false);
    setIsNotification(false);
    setIsAdd(false);
    setIsShopping(false);
    setIsSaved(false);
    setIsViewed(false);

    console.log("notification tab", isNotification);
  };

  // copy code function
  const [copyText, setCopyText] = useState(
    new Array(couponsData.length).fill(false)
  );

  const copyToClipboard = async (index, coupons) => {
    try {
      console.log(index);
      let couponCode = coupons
        .filter((_, i) => index === i)
        .map((item) => (item ? item.coupon_code : ""));
      await navigator.clipboard.writeText(couponCode);
      let copyStatus = [...copyText];
      // set text to copied
      copyStatus[index] = true;

      setCopyText(copyStatus);

      // reset text to copy after some time
      setTimeout(() => {
        copyStatus[index] = false;
        setCopyText(copyStatus);
      }, 2000);
    } catch (err) {
      console.log("Failed to copy:", err);
    }
  };
  //Function to fetch data from localStorage or create if not present
  useEffect(() => {
    const savedProductData = localStorage.getItem("savedProductData");

    console.log("savedProductData213", savedProductData);

    if (savedProductData) {
      setSavedProductData(JSON.parse(savedProductData));
    } else {
      localStorage.setItem("savedProductData", JSON.stringify([]));
    }
  }, []);

  useEffect(() => {
    console.log("hi");
    // Set a timeout to update the state after 2 minutes
    const timeoutId = setTimeout(() => {
      setNoData(false);
    }, 200);

    // Clear the timeout to prevent it from triggering if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);

  // UseEffect to perform an action based on activeTab when the component loads or activeTab changes
  useEffect(() => {
    console.log("activeTab", activeTab);
    if (activeTab) {
      switch (activeTab) {
        case "Home":
          setIsAdd(false);
          setIsShopping(true);
          setIsSaved(false);
          setIsViewed(false);
          setIsNotification(false);
          setitemUpdates(false);
          setIsSeeAll(false);
          break;

        case "Coupons":
          setIsAdd(false);
          setIsShopping(false);
          setIsSaved(false);
          setIsViewed(false);
          setIsNotification(false);
          setitemUpdates(false);
          setIsSeeAll(false);
          break;
        case "Add":
          setIsAdd(true);
          setIsShopping(false);
          setIsSaved(false);
          setIsViewed(false);
          setIsNotification(false);
          setitemUpdates(false);
          setIsSeeAll(false);
          break;
        case "Saved":
          setIsAdd(false);
          setIsShopping(false);
          setIsSaved(true);
          setIsViewed(false);
          setIsNotification(false);
          setitemUpdates(false);
          setIsSeeAll(false);
          break;
        case "Recently Viewed":
          setIsAdd(false);
          setIsShopping(false);
          setIsSaved(false);
          setIsViewed(true);
          setIsNotification(false);
          setitemUpdates(false);
          setIsSeeAll(false);
          break;
        default:
          break;
      }
    }
  }, [activeTab]); // This hook triggers whenever `activeTab` changes

  window.addEventListener("message", (event) => {
    const receivedData = event.data;

    console.log("receivedData", receivedData);

    if (receivedData.action == "detectedPageType") {
      const pageType = receivedData.pageType;
      setServerPageType(pageType);
      console.log("Detected page type:", pageType);
      // Handle the page type here
      if (pageType === "Home" || pageType === "ProductDetail") {
        setActiveTab("Home");
      } else if (pageType === "Cart" || pageType === "Checkout") {
        setActiveTab("Coupons");
      }
    }

    if (receivedData.action == "sendProductDataToback") {
      setIsShopping(false);
      setisCheckoutMode("product");
      console.log("hi from plugin");
      console.log("title " + receivedData.productData.title);
      setDomain(receivedData.domain);
      console.log(receivedData.domain);
      let titlePayload = {
        name: receivedData.productData.title,
        url: receivedData.domain,
      };
      setTitlePayloadReq(titlePayload);

      // save in localstorage
      // const currentDate = new Date('2024-05-17')
      const currentDate = new Date().toISOString();
      receivedData.productData["savedDate"] = currentDate;
      setactiveProductData(receivedData.productData);
      const newData = [...storedProductData, receivedData.productData];
      console.log(newData);

      setStoredProductData(newData);
      // localStorage.setItem('storedProductData', JSON.stringify(newData));
      getDealsData(receivedData.domain);
    }
    if (receivedData.action == "couponDomainReq") {
      setIsShopping(false);
      console.log("couponDomainReq " + receivedData.mode);
      setDomain(receivedData.domain);
      setisCheckoutMode("checkout");
      console.log(isCheckoutMode);
      function getDomain(url) {
        // Remove protocol and 'www.' if present
        let hostname = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");

        // Split the hostname by '.' to get the segments
        const segments = hostname.split(".");

        // Check if the hostname ends with a two-letter top-level domain (TLD)
        const isTwoLetterTLD = segments[segments.length - 1].length === 2;

        // Extract the domain based on the length of the TLD
        let domain;
        if (isTwoLetterTLD) {
          // For a two-letter TLD, consider the last three segments
          domain = segments.slice(-3).join(".");
        } else {
          // For other cases, consider the last two segments
          domain = segments.slice(-2).join(".");
        }

        return domain;
      }

      // Example usage:
      const subdomain = getDomain(receivedData.domain);
      console.log(subdomain);

      let couponPayload = { domain: subdomain };
      setCouponPayloadReq(couponPayload);
    }
    if (receivedData.action == "sendDomainToIframe") {
      setIsShopping(false);
      console.log("domainObj " + receivedData.domain);
      setisCheckoutMode("product");
      setDomain(receivedData.domain);
      function getDomain(url) {
        // Remove protocol and 'www.' if present
        let hostname = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");

        // Split the hostname by '.' to get the segments
        const segments = hostname.split(".");

        // Check if the hostname ends with a two-letter top-level domain (TLD)
        const isTwoLetterTLD = segments[segments.length - 1].length === 2;

        // Extract the domain based on the length of the TLD
        let domain;
        if (isTwoLetterTLD) {
          // For a two-letter TLD, consider the last three segments
          domain = segments.slice(-3).join(".");
        } else {
          // For other cases, consider the last two segments
          domain = segments.slice(-2).join(".");
        }

        return domain;
      }

      // Example usage:
      const subdomain = getDomain(receivedData.domain);
      console.log(subdomain);

      let couponPayload = { domain: subdomain };
      setCouponPayloadReq(couponPayload);
    }
    if (receivedData.action == "couponApplyingStart") {
      console.log("receivedData.domain");
      console.log(receivedData.domain);
      setCouponApplying(true);
      setIsShopping(false);
      setCouponsList(receivedData.data);
      setDomain(receivedData.domain);
    }
    if (receivedData.action == "bestcoupon") {
      console.log(receivedData);
      setCouponApplying(true);
      setIsShopping(false);
      setBestCoupon(receivedData.data);
      setDomain(receivedData.domain);
      console.log(domain);
    }
    if (receivedData.action == "iframeLoggedin") {
      const cookies = document.cookie.split(";");
      for (let cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split("=");
        if (cookieName.trim() == "phpbb3_3hnc8_sid") {
          console.log(cookieName, cookieValue);
          localStorage.setItem("sessionId", cookieValue);
        }
      }
    }
    if (receivedData.action == "nonProductPage") {
      setIsShopping(false);
      console.log("couponDomainReq " + receivedData.mode);
      setDomain(receivedData.domain);
      setisCheckoutMode("");
      console.log(isCheckoutMode);
      function getDomain(url) {
        // Remove protocol and 'www.' if present
        let hostname = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");

        // Split the hostname by '.' to get the segments
        const segments = hostname.split(".");

        // Check if the hostname ends with a two-letter top-level domain (TLD)
        const isTwoLetterTLD = segments[segments.length - 1].length === 2;

        // Extract the domain based on the length of the TLD
        let domain;
        if (isTwoLetterTLD) {
          // For a two-letter TLD, consider the last three segments
          domain = segments.slice(-3).join(".");
        } else {
          // For other cases, consider the last two segments
          domain = segments.slice(-2).join(".");
        }

        return domain;
      }

      // Example usage:
      const subdomain = getDomain(receivedData.domain);
      console.log(subdomain);

      let couponPayload = { domain: subdomain };
      setCouponPayloadReq(couponPayload);
    }
    if (receivedData.action == "socialShareData") {
      const dataFromServer = receivedData.obj;
      const constructWhatsAppUrl = (baseUrl, message) => {
        const encodedMessage = encodeURIComponent(message);
        return `${baseUrl}?text=${encodedMessage}`;
      };

      const shareMediaData = dataFromServer.map((item, index) => {
        let iconClass = "";
        let url = "";

        if (item.type === "whatsapp") {
          iconClass = "fa fa-whatsapp"; // Add the appropriate icon class for WhatsApp
          url = constructWhatsAppUrl(item.base_url, item.message);
        } else if (item.type === "Facebook") {
          iconClass = "fab fa-facebook-f"; // Add the appropriate icon class for WhatsApp
          url = constructWhatsAppUrl(item.base_url, item.message);
        } else if (item.type === "Twitter") {
          iconClass = "fab fa-twitter"; // Add the appropriate icon class for WhatsApp
          url = constructWhatsAppUrl(item.base_url, item.message);
        } else if (item.type === "Email") {
          iconClass = "fas fa-envelope"; // Add the appropriate icon class for WhatsApp
          url = constructWhatsAppUrl(item.base_url, item.message);
        } else if (item.type === "Messenger") {
          iconClass = "fab fa-facebook-messenger"; // Add the appropriate icon class for WhatsApp
          url = constructWhatsAppUrl(item.base_url, item.message);
        }
        // Add more conditions if there are more types

        return {
          icon: iconClass,
          name: item.type,
          url: url,
        };
      });

      localStorage.setItem("socialShareData", JSON.stringify(shareMediaData));
    }
  });

  let closeSlider = () => {
    if (domain) {
      const parentWindow = window.parent;
      console.log(domain);
      // Send the message to the parent window
      parentWindow.postMessage({ action: "closeSlider" }, domain);
    }
  };

  const [notifiedProduct, setNotifiedProduct] = useState();
  // get notifcation product
  const getNotifcationProduct = (product) => {
    console.log("getNotifcationProduct ", product);
    setNotifiedProduct(product);
  };
  return (
    <div className="mainContainer">
      <Toast />
      <HeaderComponent
        closeSlider={closeSlider}
        activeTab={activeTab}
        notificationTab={notificationTab}
        domain={domain}
      />
      <main className="wrapper">
        <div className="display_deals">
          {isShopping ? (
            <Yourshopping
              serverPageType={serverPageType}
              titlePayload={titlePayloadReq}
              domain={domain}
              activeProductData={activeProductData}
            />
          ) : isSaved ? (
            <SavedComponent
              savedProductData={savedProductData}
              domain={domain}
              setZIndex={setZIndex}
            />
          ) : isAdd ? (
            <AddComponent
              storedData={storedProductData}
              isCheckoutMode={isCheckoutMode}
              activeProductData={activeProductData}
              domain={domain}
              setZIndex={setZIndex}
            />
          ) : isViewed ? (
            <RecentlyViewedComponent
              storedProductData={storedProductData}
              domain={domain}
              setZIndex={setZIndex}
            />
          ) : isNotification ? (
            <NotificationComponent
              storedProductData={storedProductData}
              domain={domain}
              itemUpdates={itemUpdates}
              getNotifcationProduct={getNotifcationProduct}
              setZIndex={setZIndex}
            />
          ) : isItemUpdates ? (
            <NotificationSettingComponent
              storedProductData={storedProductData}
              domain={domain}
              product={notifiedProduct}
              setZIndex={setZIndex}
            />
          ) : isSeeAll ? (
            <SeeAllComponent
              coupons={couponsData}
              activeTab={activeTab}
              title={title}
              domain={domain}
              copyToClipboard={copyToClipboard}
              setZIndex={setZIndex}
            />
          ) : (
            <CouponsComponent
              domain={domain}
              couponPayload={couponPayloadReq}
              isCheckoutMode={isCheckoutMode}
              handleSeeAllClick={handleSeeAllClick}
              setZIndex={setZIndex}
            />
          )}
        </div>
      </main>

      <ul className="tab-bar footer" style={{ position: "sticky" }}>
        <li
          onClick={() => {
            setIsAdd(false);
            setIsShopping(true);
            setIsSaved(false);
            setIsViewed(false);
            setIsNotification(false);
            setitemUpdates(false);
            setIsSeeAll(false);
            setActiveTab("Home");
          }}
          style={{
            marginLeft: "-31px",
            color: activeTab === "Home" ? "#fd7e14" : "",
          }}
        >
          <i
            className="fa fa-home icon"
            aria-hidden="true"
            style={{ width: "57px" }}
          ></i>
          <span>Home</span>
        </li>
        <li
          onClick={() => {
            setIsAdd(false);
            setIsShopping(false);
            setIsSaved(false);
            setIsViewed(false);
            setIsNotification(false);
            setitemUpdates(false);
            setIsSeeAll(false);
            setActiveTab("Coupons");
          }}
          style={{
            marginLeft: "-97px",
            color: activeTab === "Coupons" ? "#fd7e14" : "",
          }}
        >
          <i
            className="fa fa-gift icon"
            aria-hidden="true"
            style={{ width: "57px", marginLeft: "10px" }}
          ></i>
          <span>Coupons</span>
        </li>
        <li
          onClick={() => {
            setIsAdd(true);
            setIsShopping(false);
            setIsSaved(false);
            setIsViewed(false);
            setIsNotification(false);
            setitemUpdates(false);
            setIsSeeAll(false);
            setActiveTab("Add");
          }}
          style={{
            marginLeft: "-78px",
            color: activeTab === "Add" ? "#fd7e14" : "",
          }}
        >
          <i
            className="fa fa-plus icon"
            aria-hidden="true"
            style={{ width: "44px" }}
          ></i>
          <span>Add</span>
        </li>
        <li
          onClick={() => {
            setIsAdd(false);
            setIsShopping(false);
            setIsSaved(true);
            setIsViewed(false);
            setIsNotification(false);
            setitemUpdates(false);
            setIsSeeAll(false);
            setActiveTab("Saved");
          }}
          style={{
            marginLeft: "-101px",
            color: activeTab === "Saved" ? "#fd7e14" : "",
          }}
        >
          <i
            className="fa fa-heart icon"
            aria-hidden="true"
            style={{ width: "57px" }}
          ></i>
          <span>Saved</span>
        </li>
        <li
          onClick={() => {
            setIsAdd(false);
            setIsShopping(false);
            setIsSaved(false);
            setIsViewed(true);
            setIsNotification(false);
            setitemUpdates(false);
            setIsSeeAll(false);
            setActiveTab("Recently Viewed");
          }}
          style={{
            marginLeft: "-98px",
            color: activeTab === "Recently Viewed" ? "#fd7e14" : "",
          }}
        >
          <i
            className="fa fa-history icon"
            aria-hidden="true"
            style={{ width: "113px" }}
          ></i>
          <span>Recently Viewed</span>
        </li>
      </ul>
    </div>
  );
}

export default App;
